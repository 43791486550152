import React from 'react';
import { Link } from 'react-router-dom';

const Error = ({ message = "Ocurrio un error inesperado, intente mas tarde"}) => {
  return (
    <div style={{ textAlign: 'center', padding: '20px', backgroundColor: '#f8d7da', color: '#721c24' }}>
      <h1>Error</h1>
      <p>{message}</p>

      <Link 
        to={"/dashboard"} 
        style={{
          display: 'inline-block',
          padding: '10px 20px',
          fontSize: '16px',
          fontWeight: 'bold',
          textDecoration: 'none',
          color: '#ffffff',
          backgroundColor: '#007bff',
          borderRadius: '4px',
          transition: 'background-color 0.3s ease',
        }}
        onMouseOver={(e) => e.target.style.backgroundColor = '#0056b3'}
        onMouseOut={(e) => e.target.style.backgroundColor = '#007bff'}
      >
        Volver
      </Link>
    </div>
  );
};

export default Error;
