import React, {useEffect, useState} from 'react';
import {useDispatch, connect} from 'react-redux';
import '../css/parametrizacion.css';
import ContentWrapper from '../commons/ContentWrapper';
import '../commons/css/lines.css';
import {useNavigate} from 'react-router-dom';
import SampleList from './SampleList';
import { listSamplesRequest, selectors, setSelectedSample, setMacroscopyData, getSamplesAssignationsRequest } from '../../redux/reducers/sample';
import './css/pagination.css';
import { translate } from '../../utils/translate';

const SampleManagement = ({loading, error, samples, session, sampleType, readOnly, step, directTitle, directActions, sampleAssignations, directSamples }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    dispatch(setMacroscopyData(null));

  const initialFilter = {
    dni: null,
    status: null,
    from: null,
    to: null,
    type: null,
    fullname: null,
  }

  const [filters, setFilters] = useState(initialFilter);

  const [pagination, setPagination] = useState({
    limit: 10,
    page: 1,
  });

    useEffect(() => {
        const query = {
            ...filters, limit: pagination.limit, offset: (pagination.page - 1) * pagination.limit,
            step,
            readOnly,
            typeListed: sampleType,
        };

        // si no es admin y si es macroscopia, crear diagnostico o generacion de informe, se envia assignedto=usuario actual
        if (session.userType != "ADMIN" && (step == "macroscopy" || step == "diagnosis" || step == "generateReport"))
            query.assignedTo = session.username;
        
        if (!directSamples)
          dispatch(listSamplesRequest(query));
     
    }, [pagination.page]);

  useEffect(() => {
      if (readOnly)
        dispatch(getSamplesAssignationsRequest());
  }, [dispatch]);

  const handleSearchClick = () => {
    if (filters.dni === "") filters.dni = null;
    if (filters.status === "") filters.status = null;
    if (filters.type === "") filters.type = null;

    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 1,
    }));

    doSearch();
  };

  const handleResetFilters = () => {
    setFilters(initialFilter);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const doSearch = () => {
        const query = {
          ...filters, limit: pagination.limit, offset: (pagination.page - 1) * pagination.limit,
          step,
          readOnly,
          typeListed: sampleType,
      };

      // si no es admin y si es macroscopia, crear diagnostico o generacion de informe, se envia assignedto=usuario actual
      if (session.userType != "ADMIN" && (step == "macroscopy" || step == "diagnosis" || step == "generateReport"))
          query.assignedTo = session.username;

      dispatch(listSamplesRequest(query));
  };

  const handlePageChange = (newPage) => {

    setPagination((prevPagination) => ({
      ...prevPagination,
      page: newPage,
    }));
    doSearch();
  };

  const onBack = () => {
    navigate('/dashboard');
  };

  const handleAssignDoctor = (sample) => {
    // aca segun la muestra y el estado, debe redirigir a la accion correspondiente
    dispatch(setSelectedSample(sample));
    navigate('/seleccionProfesional?step=' + step);
  };

  const generateTitle = (step, readOnly, sampleType) => {
    if (directTitle)
      return directTitle;
    if (step === 'assignTechnician')
      return 'Asignar técnico';
    if (step === 'assignDoctor')
      return 'Asignar médico';
    if (step === 'diagnosis')
      return 'Muestras - Crear diagnostico';

    if (readOnly) {
      if (sampleType === 'HISTOLOGIC')
        return 'Ver muestras histológicas';
    if (sampleType === 'CITOLOGIC')
      return 'Ver muestras citológicas';
    }
  };

  let actions = [
    { name: 'Asignar', function: handleAssignDoctor },
  ];

  // Cuando invoco al componente desde un wrapper, sobreescribo las acciones
  if (directActions) {
    actions = directActions;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('es-AR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  return (
    <ContentWrapper title={generateTitle(step, readOnly, sampleType)} loading={loading} error={error} onBack={onBack}>
      <div className="filter-container" style={{ marginBottom: '20px' }}>
          Número de documento del paciente: <input
          type="text"
          name="dni"
          placeholder="Documento Paciente"
          value={filters.dni || ''}
          onChange={handleInputChange}
        />        &nbsp;
          Nombre y apellido: <input
          type="text"
          name="fullname"
          placeholder="Nombre y apellido"
          value={filters.fullname || ''}
          onChange={handleInputChange}
        />
         &nbsp;
          Protocolo: <input
          type="text"
          name="nroProtocol"
          placeholder="Protocolo"
          value={filters.nroProtocol || ''}
          onChange={handleInputChange}
        />
         <br/><br/>
        Estado de la muestra: <select className="select-medium"
          name="status"
          value={filters.status || ''}
          onChange={handleInputChange}
        >
          <option value="">Todos</option>
          <option value="TODO">{translate('TODO')}</option>
          <option value="IN_PROGRESS">{translate('IN_PROGRESS')}</option>
          <option value="DONE">{translate('DONE')}</option>
        </select>
        &nbsp;
        Fecha desde: <input
          type="date"
          name="from"
          placeholder="Fecha Desde"
          value={filters.from || ''}
          onChange={handleInputChange}
        />
            &nbsp;Fecha hasta: <input
            type="date"
            name="to"
            placeholder="Fecha Hasta"
            value={filters.to || ''}
            onChange={handleInputChange}
        />
         &nbsp;
        {readOnly && (<><br/><br/>Responsable:   <select className="select-medium"
            name="assignedTo"
            value={filters.assignedTo || ''}
            onChange={handleInputChange}
        >
            <option value="">-- Seleccione un responsable --</option>
            {sampleAssignations && sampleAssignations.map((assignment, index) => (
                <option key={index} value={assignment}>
                    {assignment}
                </option>
            ))}
        </select>
    </> )} &nbsp; &nbsp;
        <button className="search-button" onClick={handleSearchClick}>Buscar</button>
        <button className="search-button" onClick={handleResetFilters}>Borrar filtros</button>
        <br/><br/>
      </div>

      {(!samples || (samples && samples.length === 0)) && (!directSamples || (directSamples && directSamples.length === 0)) ? (
        <p>No se encontraron muestras</p>
      ) : (
        <>
          <SampleList samples={directSamples ? directSamples : samples}  readOnly={readOnly}  actions={readOnly ? [] : actions} />
          <div className="pagination-controls">
            <button
              className="pagination-button"
              disabled={pagination.page === 1}
              onClick={() => handlePageChange(pagination.page - 1)}
            >
              ⟵ Anterior
            </button>
            <span className="pagination-page">Página {pagination.page}</span>
            <button
              className="pagination-button"
              disabled={samples && samples.length < pagination.limit}
              onClick={() => handlePageChange(pagination.page + 1)}
            >
              Siguiente ⟶
            </button>
          </div>
        </>
      )}
    </ContentWrapper>
  );
};

const mapStateToProps = (state) => ({
    samples: selectors.samples(state),
    loading: selectors.loading(state),
    error: selectors.error(state),
    session: selectors.session(state),
    sampleAssignations: selectors.sampleAssignations(state)
});

export default connect(mapStateToProps)(SampleManagement);
