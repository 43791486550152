import axios from 'axios';
import {delay, select} from 'redux-saga/effects';
import {selectors as sessionSelectors} from "../redux/reducers/sessionReducer"

export function* getSessionToken() {
    const session = yield select(sessionSelectors.session);
    
    if (!session || !session.refreshToken) {
        throw new Error('Sesion no valida');
    }

    return session.refreshToken;
}

export function* postRequestWithToken(url, data) {
    try {

        // delay agregado para simular retardo
   
        
        const token = yield getSessionToken();
        const cfg = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        const response = yield axios.post(url, data, cfg);

        return response;
    } catch (error) {
        throw error;
    }
}

export function* getRequestWithToken(url) {
    try {

        // delay agregado para simular retardo
     
        const token = yield getSessionToken();
        const cfg = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        return yield axios.get(url, cfg);
    } catch (error) {
        throw error;
    }
}

export function* getRequestWithTokenParams(url, query) {
    try {

        // delay agregado para simular retardo
     
        const token = yield getSessionToken();
        const cfg = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
            params: query,
        };

        return yield axios.get(url, cfg);
    } catch (error) {
        throw error;
    }
}



export function* putRequestWithToken(url, data) {
    try {

        // delay agregado para simular retardo
        yield delay(500);

        const token = yield getSessionToken();
        const cfg = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        const response = yield axios.put(url, data, cfg);
        return response.data;
    } catch (error) {
        throw error;
    }
}

export function* deleteRequestWithToken(url) {
    try {

        // delay agregado para simular retardo
        yield delay(500);

        const token = yield getSessionToken();
        const cfg = {
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        };

        const response = yield axios.delete(url, cfg);
        return response.data;
    } catch (error) {
        throw error;
    }
}
