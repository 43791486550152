import React, { useState, useRef } from 'react';
import { useDispatch, connect } from 'react-redux';
import './css/parametrizacion.css';
import {
  selectors,
} from '../redux/reducers/sample';
import ContentWrapper from './commons/ContentWrapper';
import './commons/css/lines.css';
import { useNavigate } from 'react-router-dom';
import Modal from './commons/Modal';
import ConfirmationModal from './commons/ConfirmationModal';
import 'react-quill/dist/quill.snow.css';
import { selectors as sessionSelectors } from "../redux/reducers/sessionReducer";
import { findReportDescriptionForSample } from '../utils/sample';
import PdfViewer from './commons/PdfViewer';

const ImprimirInforme = ({ loading, error, modalLoading, session, selectedSample }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const base64Data = findReportDescriptionForSample(selectedSample);  // Este es el base64 del PDF

  const handlePrint = (e) => {
    e.preventDefault();
    setShowModalConfirmation(true); // Muestra la confirmación antes de imprimir
  };

  const confirmPrint = () => {
    setShowModalConfirmation(false);

    // Crear un enlace para la descarga del PDF
    const link = document.createElement('a');
    const byteCharacters = atob(base64Data);  // Decodifica el base64
    const byteArray = new Uint8Array(byteCharacters.length);

    // Rellena el Uint8Array con los bytes del base64
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }

    // Crear un Blob con los bytes del PDF
    const blob = new Blob([byteArray], { type: 'application/pdf' });

    // Crear un URL para el Blob
    const blobURL = URL.createObjectURL(blob);

    // Asignar el enlace de descarga
    link.href = blobURL;
    link.download = 'informe.pdf';  // Nombre del archivo PDF

    // Simular el clic en el enlace para descargar el PDF
    link.click();

    // Limpiar el objeto URL
    URL.revokeObjectURL(blobURL);
  };

  return (
    <ContentWrapper title="Imprimir informe final" loading={loading} error={error}>
      <form onSubmit={handlePrint} className="informe-form">
        <h3>Vista previa del informe a imprimir</h3>

        {/* Aquí mostramos el PdfViewer solo para vista previa */}
        <div style={{ width: '120%', height: '120%', overflow: 'auto', margin: '0 auto' }}>
          <PdfViewer 
            base64Data={base64Data} 
            scale={2} 
            ismacroscopy={selectedSample.type === "HISTOLOGIC" && selectedSample.subtype === "REGULAR"} 
          />
        </div>

        <button type="submit" className="submit-button-param">Imprimir</button>
      </form>

      {showModalConfirmation && (
        <ConfirmationModal
          message="Confirma la impresión del informe final?"
          onConfirm={confirmPrint}
          onCancel={() => setShowModalConfirmation(false)}
        />
      )}
    </ContentWrapper>
  );
};

const mapStateToProps = (state) => ({
  modalLoading: selectors.modalLoading(state),
  loading: selectors.loading(state),
  error: selectors.error(state),
  session: sessionSelectors.session(state),
  selectedSample: selectors.selectedSample(state),
});

export default connect(mapStateToProps)(ImprimirInforme);
