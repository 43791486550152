import React, { createContext, useContext, useState, useEffect } from 'react';
import { setNotificationAssign, setSampleUpdate } from '../redux/reducers/sample';
import { useDispatch } from 'react-redux';

// Crea el contexto del WebSocket
const WebSocketContext = createContext(null);

// Proveedor del WebSocket
export const WebSocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const newSocket = new WebSocket('wss://sitrapt.com.ar/ws');
    const pingInterval = setInterval(() => {
      if (newSocket.readyState === WebSocket.OPEN) {
        newSocket.send(JSON.stringify({ type: 'ping' }));
      }
    }, 5000);

    newSocket.onopen = () => {
      console.log('WebSocket connected');
    };

    newSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.operationType == "assignation") {
        dispatch(setNotificationAssign(data));
        return;
      };

      if (data && data.updatedSample)
        dispatch(setSampleUpdate(data.updatedSample));
    };

    newSocket.onclose = () => {
      console.log('WebSocket closed');
      clearInterval(pingInterval);
    };

    setSocket(newSocket);

    // Cleanup
    return () => {
      clearInterval(pingInterval);
      newSocket.close();
    };
  }, []);

  return (
    <WebSocketContext.Provider value={socket}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Hook para usar el contexto
export const useWebSocket = () => {
  return useContext(WebSocketContext);
};
