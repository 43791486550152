export const mapError = (error) => {
    if (error.code == 'ERR_NETWORK')
        return "Se produjo un error de red";
    if (error.code == 'ERR_BAD_RESPONSE')
        return "Se produjo un error inesperado";
    if (error.code == 'ERR_BAD_REQUEST')
        return "Se produjo un error inesperado";

    const errorResponse = error.response ? error.response : error.response;
    if (errorResponse)
        return errorResponse.message;

    return "Se produjo un error inesperado";
}