import dayjs from 'dayjs';

export const formatDate = (dateString) => {
	const date = new Date(dateString);
	return date.toLocaleDateString('es-AR', {
		day: '2-digit',
		month: '2-digit',
		year: 'numeric',
	});
};

export const formatDateOk = (dateString) => {
	return dayjs(dateString).format('DD/MM/YYYY');
};