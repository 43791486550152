export const types = {
  LIST_PROFESSIONAL_REQUEST: 'LIST_PROFESSIONAL_REQUEST',
  LIST_PROFESSIONAL_SUCCESS: 'LIST_PROFESSIONAL_SUCCESS',
  LIST_PROFESSIONAL_FAILURE: 'LIST_PROFESSIONAL_FAILURE',
}

const initialState = {
    professionals: undefined,
    loading: false,
    error: null,
    modalLoading: false,
};

const professional = (state = initialState, action) => {
    switch (action.type) {
        case types.LIST_PROFESSIONAL_REQUEST:
            return {...state, loading: true, error: null};
        case types.LIST_PROFESSIONAL_SUCCESS:
            return {...state, loading: false, professionals: action.professionals, error: null
            };
        case types.LIST_PROFESSIONAL_FAILURE:
            return {...state, loading: false, error: action.error, professionals: null };
        default:
            return state;
    }
};

export const listProfessionalRequest = ( query ) => ({
  type: types.LIST_PROFESSIONAL_REQUEST,
  query: query,
  loading: true,
});
  
export const listProfessionalSuccess = (professionals) => ({
  type: types.LIST_PROFESSIONAL_SUCCESS,
  professionals,
  loading: false,
});

export const listProfessionalFailure = (code, msg) => ({
  type: types.LIST_PROFESSIONAL_FAILURE,
  error: { code, msg, show: true  },
  loading: false,
});

export const selectors = {
  loading: state => state.professional.loading,
  modalLoading: state => state.professional.modalLoading,
  error: state => state.professional.error,
  professionals: state => state.professional.professionals,
};

export default professional;


