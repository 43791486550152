const initialState = {
  data: null,
  loading: false,
  error: null
};

export const types = {
  GET_SYSTEM_DATA_REQUEST: 'GET_SYSTEM_DATA_REQUEST',
  GET_SYSTEM_DATA_SUCCESS: 'GET_SYSTEM_DATA_SUCCESS',
  GET_SYSTEM_DATA_FAILURE: 'GET_SYSTEM_DATA_FAILURE',
}

const system = (state = initialState, action) => {  
  switch (action.type) {
    case types.GET_SYSTEM_DATA_REQUEST:
      return { ...state, loading: true, error: null };
    case types.GET_SYSTEM_DATA_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case types.GET_SYSTEM_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

export const getSystemData = () => ({
  type: types.GET_SYSTEM_DATA_REQUEST,
});

export const getSystemDataSuccess = (response) => ({
  type: types.GET_SYSTEM_DATA_SUCCESS,
  payload: response.data
});

export const getSystemDataFailure = (error) => ({
  type: types.GET_SYSTEM_DATA_FAILURE,
  payload: error.message
});

export const selectors = {
  getSystemData: ({ state }) => state.system.data,
};

export default system;
