import {call, put, takeLatest, delay} from 'redux-saga/effects';
import axios from 'axios';
import {
    addSampleFailure,
addSampleSuccess, types, listSampleSuccess, listSampleFailure, setSampleOwnerSuccess, setSampleOwnerFailure, macroscopySuccess, macroscopyFailure,
listDashSuccess,
listDashFailure,
generateDiagnosisFailure,
generateDiagnosisSuccess, updateArchiveSampleFailure, updateArchiveSampleSuccess, getArchiveSampleFailure, getArchiveSampleSuccess,
getSampleLocationsSuccess,
getSampleLocationsFailure,
sendReportSuccess,
sendReportFailure,
getSamplesAssignationsSuccess,
getSamplesAssignationsFailure,
getAllSamplesFailure,
getAllSamplesSuccess
} from '../reducers/sample';
import  {mapError} from '../../errorHandler';
import { postRequestWithToken, getRequestWithToken } from '../../security/api';

const baseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL;

const printLabelsAddSample = (sample) => {
    const labels = getLabelsToPrintAddSample(sample);
    labels.forEach(label => generateAndDownloadLabel(label));
}

const getLabelsToPrintAddSample = (sample)=>{
    const labelsToPrint = [];
    labelsToPrint.push(sample.protocolNumber);
    if (sample.subtype === 'FREEZING'){
        const histologyStep = sample.steps.find(step => step.name === 'HISTOLOGY_PROCESS');
        const cassette = histologyStep.cassettes[0];
        const glass = cassette.glassSlides[0];
        labelsToPrint.push(cassette.cassetteId);
        labelsToPrint.push(glass.glassSlideId);
    }
    return labelsToPrint;
}

const printLabelsMacroscopy = (sample) => {
    const labels = getLabelsToPrintMacroscopy(sample);
    labels.forEach(label => generateAndDownloadLabel(label));
}

const getLabelsToPrintMacroscopy = (sample)=>{
    const labelsToPrint = [];
    if (sample.subtype === 'REGULAR'){
        const histologyStep = sample.steps.find(step => step.name === 'HISTOLOGY_PROCESS');
        const cassettes = histologyStep.cassettes;
        cassettes.forEach(cassette => {
            labelsToPrint.push(cassette.cassetteId);
            cassette.glassSlides.forEach(glass => {
                labelsToPrint.push(glass.glassSlideId);
            })
        })
    }
    return labelsToPrint;
}

const generateAndDownloadLabel = (text) => {
    console.log("label: " + text);
    // 9mm alto x 28mm ancho de total etiqueta
    const labelWidth = 224;
    const labelHeight = 72;
    const barcodeHeight = 70;
    const barWidth = 1;
    const blankBarSpaceWidth = 1;

    // Comando ZPL para la etiqueta con el tamaño y posicionamiento ajustado
    const zpl = `
^XA
^PW${labelWidth}
^LL${labelHeight}
^FO10,30
^BY${barWidth},${blankBarSpaceWidth},${barcodeHeight}
^BCN,${barcodeHeight},Y,N,N
^FD${text}^FS
^XZ
`;

    // Convertir la cadena ZPL a un blob
    const blob = new Blob([zpl], { type: 'text/plain' });

    // Crear un objeto URL a partir del blob
    const url = URL.createObjectURL(blob);

    // Usar la API de descarga nativa para iniciar la descarga
    const a = document.createElement("a");
    a.href = url;
    a.download = `${text}_barcode.zpl`;
    document.body.appendChild(a);  // Necesario para Firefox
    a.click();

    // Limpiar el URL objeto y el enlace después de descargar
    URL.revokeObjectURL(url);
    document.body.removeChild(a);
}

function* addSampleRequest({sampleData}) {
    try {

        const url = `${baseUrl}/api/sample`;

        const response = yield call(postRequestWithToken, url, {
            ...sampleData,
        });

        printLabelsAddSample(response.data);
        const labels = getLabelsToPrintAddSample(response.data);
        yield put(addSampleSuccess(labels));
    } catch (error) {
        const msg = mapError(error);
        if (error.code == 'ERR_NETWORK') {
            yield put(addSampleFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }
        yield put(addSampleFailure(error.code, msg));

    }

}

function* getSamples({query}) {
    try {

        if (query.step === "assignDoctor")
            query.stepName = "ASSIGN_DOCTOR";
        if (query.step === "assignTechnician")
            query.stepName = "ASSIGN_TECHNICIAN";
        if (query.step === "diagnosis") {
            query.stepName = "DIAGNOSTIC";
        }
        if (query.step === "generateReport")
            query.stepName = "GENERATE_REPORT";
        if (query.step === "sendReport")
            query.stepName = "SEND_REPORT";
        if (query.step === "archiveSample")
            query.stepName = "ARCHIVE_SAMPLES";

        let url = '';

        // Es consulta de muestras general
        if (query.readOnly) {
            query.type = query.typeListed;

            // Hay un filtro, entonces busco en todas las muestras
            if (query.fullname || query.nroProtocol || query.assignedTo)
                query.limit = null;

            url = `${baseUrl}/api/sample/search`;
        }
        else
            // Es consulta para asignacion segun el paso
            url = `${baseUrl}/api/sample/searchByStep`;

        const response = yield call(postRequestWithToken, url, query);


        if (query.nroProtocol || query.fullname || query.assignedTo) {
            const filteredSamples = response.data.samples.filter(sample =>
                (!query.nroProtocol || sample.protocolNumber.toLowerCase().includes(query.nroProtocol.toLowerCase())) &&
                (!query.fullname || sample.patientName.toLowerCase().includes(query.fullname.toLowerCase())) &&
                (!query.assignedTo || sample.assignedTo === query.assignedTo)
            );
            yield put(listSampleSuccess(filteredSamples));
        }else {
            yield put(listSampleSuccess(response.data.samples));
        }


    } catch (error) {

        const msg = mapError(error);
        if (error.code == 'ERR_NETWORK') {
            yield put(listSampleFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }
        yield put(listSampleFailure(error.code, msg));
    }
}


function* getDashboardStats({ session }) {
    try {

        const url = `${baseUrl}/api/sample/searchByStepAll`;
        const query = {
            username: session.username,
            usertype: session.userType,
        };       
        const response = yield call(postRequestWithToken, url,query);

        const dashresult = {
            macroscopy : response.data.stepSizes["MACROSCOPY_count"],
            assignDoctor : response.data.stepSizes["ASSIGN_DOCTOR_count"],
            assignTechnician : response.data.stepSizes["ASSIGN_TECHNICIAN_count"],
            diagnosticReady : response.data.stepSizes["DIAGNOSTIC_count"],
            histoProcessing : response.data.stepSizes["HISTOLOGY_PROCESS_count"],
            reportReady : response.data.stepSizes["GENERATE_REPORT_count"],
            sendReportReady : response.data.stepSizes["SEND_REPORT_count"],
            archiveSample : response.data.stepSizes["ARCHIVE_SAMPLES_count"],
        };
     
        yield put(listDashSuccess(dashresult));
    } catch (error) {

        const msg = mapError(error);
        if (error.code == 'ERR_NETWORK') {
            yield put(listDashFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }
        yield put(listDashFailure(error.code, msg));
    }
}


function* setSampleOwner({ sample }) {
    try {

        const url = `${baseUrl}/api/sample/${sample.protocolNumber}/assignation`;

        const response = yield call(postRequestWithToken, url, sample);

        yield put(setSampleOwnerSuccess(response.data));
    } catch (error) {
        const msg = mapError(error);
        if (error.code == 'ERR_NETWORK') {
            yield put(setSampleOwnerFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }
        yield put(setSampleOwnerFailure(error.code, msg));
    }
}

function* macroscopyRequest({macroscopyData}) {
    try {

        const url = `${baseUrl}/api/sample/${macroscopyData.protocolNumber}/macroscopy`;

        const response = yield call(postRequestWithToken, url, macroscopyData);

        printLabelsMacroscopy(response.data);

        const labels = getLabelsToPrintMacroscopy(response.data);

        yield put(macroscopySuccess(labels));
    } catch (error) {

        const msg = mapError(error);
        if (error.code == 'ERR_NETWORK') {
            yield put(macroscopyFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }

        yield put(macroscopyFailure(error.code, msg));

    }

}

function* generateDiagnosis({sampleData}) {
    try {

        const url = `${baseUrl}/api/diagnosis/${sampleData.protocolNumber}`;

        const response = yield call(postRequestWithToken, url, {
            ...sampleData,
        });

        yield put(generateDiagnosisSuccess(response.data));
    } catch (error) {

        const msg = mapError(error);
        if (error.code == 'ERR_NETWORK') {
            yield put(generateDiagnosisFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }

        yield put(generateDiagnosisFailure(error.code, msg));

    }
}

function* generateInforme({sampleData}) {
    try {

        const url = `${baseUrl}/api/report/${sampleData.protocolNumber}`;

        const response = yield call(postRequestWithToken, url, {
            ...sampleData,
        });

        yield put(generateDiagnosisSuccess(response.data));
    } catch (error) {

        const msg = mapError(error);
        if (error.code == 'ERR_NETWORK') {
            yield put(generateDiagnosisFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }

        yield put(generateDiagnosisFailure(error.code, msg));

    }
}

function* updateArchiveSample({sampleData}) {
    try {

        const url = `${baseUrl}/api/sample/${sampleData.protocolNumber}/archive`;

        const response = yield call(postRequestWithToken, url, {
            ...sampleData,
        });

        yield put(updateArchiveSampleSuccess(response.data));
    } catch (error) {

        const msg = mapError(error);
        if (error.code == 'ERR_NETWORK') {
            yield put(updateArchiveSampleFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }

        yield put(updateArchiveSampleFailure(error.code, msg));

    }
}

function* getArchiveSample({sampleData}) {
    try {

        const url = `${baseUrl}/api/sample/${sampleData.protocolNumber}/archive`;

        const response = yield call(getRequestWithToken, url, {
            ...sampleData,
        });

        yield put(getArchiveSampleSuccess(response.data));
    } catch (error) {

        const msg = mapError(error);
        if (error.code == 'ERR_NETWORK') {
            yield put(getArchiveSampleFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }

        yield put(getArchiveSampleFailure(error.code, msg));

    }
}

function* getSampleLocations({ query }) {
    try {
        const querydata = {
            status: null,
            from: null,
            to: null,
        };

        const url = `${baseUrl}/api/sample/search`;

        const response = yield call(postRequestWithToken, url, querydata);

        const locationCount = {};

        // Recorre todas las muestras para calcular los totales por ubicación
        response.data.samples.forEach(sample => {
            const archiveStep = sample.steps.find(step => step.name === "ARCHIVE_SAMPLES");
            if (archiveStep && archiveStep.sampleArchiveLocationDescription) {
                const [location, capacidad] = archiveStep.sampleArchiveLocationDescription.split('|');
                const parsedCapacidad = parseInt(capacidad, 10);

                if (!locationCount[location]) {
                    locationCount[location] = {
                        count: 0,
                        capacidad: parsedCapacidad || 0
                    };
                }
                locationCount[location].count += 1;
            }
        });

        // Si no se proporciona un protocolNumber, devolver todas las ubicaciones
        if (!query.protocolNumber) {
            const locationList = Object.keys(locationCount).map(location => ({
                location,
                count: locationCount[location].count,
                capacidad: locationCount[location].capacidad,
            }));
            yield put(getSampleLocationsSuccess(locationList));
            return;
        }

        // Normaliza el protocolNumber a minúsculas
        const normalizedProtocol = query.protocolNumber.toLowerCase();

        // Busca la muestra con el protocolNumber, ignorando mayúsculas/minúsculas
        const filteredSample = response.data.samples.find(
            sample => sample.protocolNumber.toLowerCase() === normalizedProtocol
        );

        if (!filteredSample) {
            // Si no hay ninguna muestra con ese protocolNumber, devolver vacío
            yield put(getSampleLocationsSuccess([]));
            return;
        }

        // Encuentra la ubicación de la muestra especificada
        const archiveStep = filteredSample.steps.find(step => step.name === "ARCHIVE_SAMPLES");
        if (!archiveStep || !archiveStep.sampleArchiveLocationDescription) {
            yield put(getSampleLocationsSuccess([]));
            return;
        }

        const [targetLocation, capacidad] = archiveStep.sampleArchiveLocationDescription.split('|');
        const parsedCapacidad = parseInt(capacidad, 10);

        // Devuelve solo la ubicación de la muestra especificada, pero con el total de muestras en esa ubicación
        const locationList = [
            {
                location: targetLocation,
                count: locationCount[targetLocation].count, // Total de muestras en esta ubicación
                capacidad: parsedCapacidad || 0,
            }
        ];

        yield put(getSampleLocationsSuccess(locationList));
    } catch (error) {
        const msg = mapError(error);
        if (error.code === 'ERR_NETWORK') {
            yield put(getSampleLocationsFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }

        yield put(getSampleLocationsFailure(error.code, msg));
    }
}


                                      


function* getSampleAssignations() {
    try {

        const querydata = {
            status: null,
            from: null,
            to: null,
        };

        const url = `${baseUrl}/api/sample/search`;

        const response = yield call(postRequestWithToken, url, querydata);

        let professionals  = [];


        response.data.samples.forEach(sample => {
            if (sample.assignedTo !== null) {
                professionals.push(sample.assignedTo);
            }
        });
        const result = [...new Set(professionals)];

        yield put(getSamplesAssignationsSuccess(result));

    } catch (error) {
        const msg = mapError(error);
        if (error.code === 'ERR_NETWORK') {
            yield put(getSamplesAssignationsFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }

        yield put(getSamplesAssignationsFailure(error.code, msg));
    }
}

function* getAllSamples() {
    try {

        const querydata = {
            status: null,
            from: null,
            to: null,
        };

        const url = `${baseUrl}/api/sample/search`;

        const response = yield call(postRequestWithToken, url, querydata);

        yield put(getAllSamplesSuccess(response.data.samples));

    } catch (error) {
        const msg = mapError(error);
        if (error.code === 'ERR_NETWORK') {
            yield put(getAllSamplesFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }

        yield put(getAllSamplesFailure(error.code, msg));
    }
}



function* sendReport({sampleData}) {
    try {

        const url = `${baseUrl}/api/report/${sampleData.protocolNumber}/send`;

        const response = yield call(postRequestWithToken, url, {
            ...sampleData,
        });

        yield put(sendReportSuccess(response.data));
    } catch (error) {

        const msg = mapError(error);
        if (error.code == 'ERR_NETWORK') {
            yield put(sendReportFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }

        yield put(sendReportFailure(error.code, msg));

    }
}

export function* watchSampleSaga() {
    yield takeLatest(types.ADD_SAMPLE_REQUEST, addSampleRequest);
    yield takeLatest(types.LIST_SAMPLE_REQUEST, getSamples);
    yield takeLatest(types.SET_SAMPLE_OWNER_REQUEST, setSampleOwner);
    yield takeLatest(types.MACROSCOPY_REQUEST, macroscopyRequest);
    yield takeLatest(types.GET_DASHBOARD_STATS_REQUEST, getDashboardStats);
    yield takeLatest(types.GENERATE_DIAGNOSIS_REQUEST, generateDiagnosis);
    yield takeLatest(types.GENERATE_INFORME_REQUEST, generateInforme);
    yield takeLatest(types.UPDATE_ARCHIVE_SAMPLE_REQUEST, updateArchiveSample);
    yield takeLatest(types.GET_ARCHIVE_SAMPLE_REQUEST, getArchiveSample);
    yield takeLatest(types.GET_SAMPLE_LOCATIONS_REQUEST, getSampleLocations);
    yield takeLatest(types.SEND_REPORT_REQUEST, sendReport);
    yield takeLatest(types.GET_SAMPLES_ASSIGNATIONS_REQUEST, getSampleAssignations);
    yield takeLatest(types.GET_ALL_SAMPLES_REQUEST, getAllSamples);
}
