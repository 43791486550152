import React, {useEffect, useState} from 'react';
import {useDispatch, connect} from 'react-redux';
import '../css/parametrizacion.css';
import ContentWrapper from '../commons/ContentWrapper';
import '../commons/css/lines.css';
import {useNavigate} from 'react-router-dom';
import SampleList from './SampleList';
import { listSamplesRequest, selectors, setSelectedSample, setMacroscopyData } from '../../redux/reducers/sample';
import './css/pagination.css';
import { translate } from '../../utils/translate';

const SampleHistology = ({loading, error, samples, session}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

  const initialFilter = {
    dni: null,
  //  status: 'IN_PROGRESS', // TODO: ver que estado deberia buscar para el caso de histologia
    from: null,
    to: null,
    stepName: "MACROSCOPY",
    type: 'HISTOLOGIC', // para macroscopia es este tipo de muestra nomas
  }

  const [filters, setFilters] = useState(initialFilter);

  const [pagination, setPagination] = useState({
    limit: 10,
    page: 1,
  });

    useEffect(() => {
        const query = {
            ...filters, limit: pagination.limit, offset: (pagination.page - 1) * pagination.limit,
            // si no es admin y si es macroscopia, crear diagnostico o generacion de informe, se envia assignedto=usuario actual
            assignedTo: session.userType != 'ADMIN' ? session.username :null,
        };
        dispatch(listSamplesRequest(query, session));
    }, [dispatch, pagination]);

  const handleSearchClick = () => {
    if (filters.dni === "") filters.dni = null;
    if (filters.status === "") filters.status = null;
    if (filters.type === "") filters.type = null;

    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 1,
    }));

        const query = {
          // si no es admin y si es macroscopia, crear diagnostico o generacion de informe, se envia assignedto=usuario actual
            ...filters, limit: pagination.limit, offset: 0, assignedTo: session.userType != 'ADMIN' ? session.username :null,
        };
        dispatch(listSamplesRequest(query, session));
    };

  const handleResetFilters = () => {
    setFilters(initialFilter);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handlePageChange = (newPage) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: newPage,
    }));
  };

  const onBack = () => {
    // limpio cualquier informacion que se haya ingresado
    dispatch(setSelectedSample(null));
    dispatch(setMacroscopyData(null));
    navigate('/dashboard');
  };

  const handleMacroscopy = (sample) => {
    // aca segun la muestra y el estado, debe redirigir a la accion correspondiente
    dispatch(setSelectedSample(sample));
    navigate('/macroscopiaRegistro');
  };

  const actions = [
    { name: 'Macroscopía', function: handleMacroscopy },
  ];
  return (
    <ContentWrapper title="Macroscopía" loading={loading} error={error} onBack={onBack}>
      <div className="filter-container" style={{ marginBottom: '20px' }}>
        Número de documento del paciente: <input
          type="text"
          name="dni"
          placeholder="DNI del paciente"
          value={filters.dni || ''}
          onChange={handleInputChange}
        />
        &nbsp;Estado de la muestra: <select className="select-medium"
          name="status"
          value={filters.status || ''}
          onChange={handleInputChange}
        >
          <option value="">Todos</option>
          <option value="PENDIENTE">{translate('TODO')}</option>
          <option value="ASIGNADO">{translate('IN_PROGRESS')}</option>
          <option value="FINALIZADO">{translate('DONE')}</option>
        </select>
        <br/><br/>
        Fecha desde: <input
          type="date"
          name="from"
          placeholder="Fecha Desde"
          value={filters.from || ''}
          onChange={handleInputChange}
        />
            &nbsp;Fecha hasta: <input
            type="date"
            name="to"
            placeholder="Fecha Hasta"
            value={filters.to || ''}
            onChange={handleInputChange}
        />
        <button className="search-button" onClick={handleSearchClick}>Buscar</button>
        <button className="search-button" onClick={handleResetFilters}>Borrar filtros</button>
        <br/><br/>
      </div>

      {!samples || (samples && samples.length === 0) ? (
        <p>No se encontraron muestras</p>
      ) : (
        <>
          <SampleList samples={samples} actions={actions} histology={true}/>
          <div className="pagination-controls">
            <button
              className="pagination-button"
              disabled={pagination.page === 1}
              onClick={() => handlePageChange(pagination.page - 1)}
            >
              ⟵ Anterior
            </button>
            <span className="pagination-page">Página {pagination.page}</span>
            <button
              className="pagination-button"
              disabled={samples && samples.length < pagination.limit}
              onClick={() => handlePageChange(pagination.page + 1)}
            >
              Siguiente ⟶
            </button>
          </div>
        </>
      )}
    </ContentWrapper>
  );
};

const mapStateToProps = (state) => ({
    samples: selectors.samples(state),
    loading: selectors.loading(state),
    error: selectors.error(state),
    session: selectors.session(state)
});

export default connect(mapStateToProps)(SampleHistology);
