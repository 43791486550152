import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const WorkChart = ({ histograms }) => {
  const processData = (histogram) => {
    const labels = histogram.values.map((entry) => entry.date.split('T')[0]);
    const data = histogram.values.map((entry) => entry.samples);

    return {
      labels,
      datasets: [
        {
          label: `Total Muestras Ingresadas (${histogram.timeframe})`,
          data,
          backgroundColor: 'rgba(46, 204, 113, 0.6)',
          barThickness: 30, // Ajusta el grosor de las barras
        },
      ],
    };
  };

  const data7d = processData(histograms[0]);

  const options = {
    responsive: true,
    maintainAspectRatio: false, // Desactiva la relación de aspecto predeterminada
    aspectRatio: 2, // Ajusta la proporción ancho/alto. Puedes modificar este valor.
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `Total Muestras Ingresadas (${histograms[0].timeframe})`,
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Opcional: para ocultar las líneas de la grilla
        },
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  return <Bar data={data7d} options={options} />;
};

export default WorkChart;
