import {call, put, takeLatest, delay } from 'redux-saga/effects';
import axios from 'axios';
import {
    listProfessionalFailure,
    listProfessionalSuccess,
    types
} from '../reducers/professional';
import  {mapError} from '../../errorHandler';
import { postRequestWithToken } from '../../security/api';

const baseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL;

function* getProfessionals( {query} ) {
    try {
   
        const url = `${baseUrl}/api/users/search`;

        let proftype = null;
        const step = query.step;
        const type = query.type;
        
        if (type === 'HISTOLOGIC' && step === 'assignTechnician')
            proftype = 'HISTOTECHNICIAN';

        if (type=== 'CITOLOGIC' && step === 'assignTechnician')
            proftype = 'CITOTECHNICIAN';

        if (type === 'HISTOLOGIC' && step === 'assignDoctor')
            proftype = 'DOCTOR';

        if (type === 'CITOLOGIC' && step === 'assignDoctor')
            proftype = 'DOCTOR';
        

        const  queryProf = {
            isActive: 'false',
            userType: proftype,
        }

        const response = yield call(postRequestWithToken, url, queryProf);
        
        yield put(listProfessionalSuccess(response.data));
    } catch (error) {
        const msg = mapError(error);
        if (error.code == 'ERR_NETWORK') {
            yield put(listProfessionalFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }
        yield put(listProfessionalFailure(error.code, msg));
    }
}

export function* watchProfessionalSaga() {
    yield takeLatest(types.LIST_PROFESSIONAL_REQUEST, getProfessionals);
}