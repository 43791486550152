import React from 'react';
import './css/SuccessModal.css';

const Modal = ({ error, onClose, modalLoading, text }) => {
  // Transformar el texto con [br] en saltos de línea reales
  const processText = (inputText) => {
    if (!inputText) return null;
    return inputText.split('[br]').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < inputText.split('[br]').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {modalLoading ? (
          <div className="spinner-container">
            <div className="spinner"></div>
            <p>Cargando...</p>
          </div>
        ) : (
          <>
            {error == null && (
              <>
                <h2>Operación exitosa</h2>
                <p>{processText(text)}</p>
              </>
            )}
            {error != null && (
              <>
                <h2>Se produjo un error</h2>
                <p>{error.msg}</p>
              </>
            )}
            <button className="close-button" onClick={onClose}>
              Cerrar
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
