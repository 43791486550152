import React, {useState} from 'react';
import './css/registrarMuestra.css';

const DatosProfesional = ({formData, handleChange}) => {
	const [errors, setErrors] = useState({});

	const REQUIRED_ERROR_MESSAGE = "Este campo es obligatorio.";


	const handleBlur = (e) => {
		const {name, value} = e.target;
		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: !value ? REQUIRED_ERROR_MESSAGE : '',
		}));
	};

	const handleInvalid = (e) => {
		e.preventDefault();
		handleBlur(e);
		const firstInvalidElement = document.querySelector('.input-invalid');
		if (firstInvalidElement) {
			firstInvalidElement.scrollIntoView({behavior: 'smooth', block: 'center'});
		}
	};
	return (
		<>

			<h2>Datos del profesional médico</h2>

			<div className="input-row">

				<div className="form-group">
					<label>Nombre y apellido:</label>
					<input
						type="text"
						name="datosMedicoNombreApellido"
						value={formData.datosMedicoNombreApellido}
						onChange={handleChange}
						onInvalid={handleInvalid}
						onBlur={handleBlur}
						required
						className={errors.datosMedicoNombreApellido ? 'input-invalid' : ''}
					/>
					{errors.datosMedicoNombreApellido && <div className="input-invalid-disclaimer ">{errors.datosMedicoNombreApellido}</div>}
				</div>

				<div className="form-group">
					<label>Especialidad:</label>
					<input
						type="text"
						name="especialidad"
						value={formData.especialidad}
						onChange={handleChange}
						onInvalid={handleInvalid}
						onBlur={handleBlur}
						required
						className={errors.especialidad ? 'input-invalid' : ''}
					/>
					{errors.especialidad && <div className="input-invalid-disclaimer ">{errors.especialidad}</div>}
				</div>

			</div>
			<div className="input-row">

				<div className="form-group">
					<label>Número de Matrícula:</label>
					<input
						type="text"
						name="nroMatricula"
						value={formData.nroMatricula}
						onChange={handleChange}
						onInvalid={handleInvalid}
						onBlur={handleBlur}
						required
						className={errors.nroMatricula ? 'input-invalid' : ''}
					/>
					{errors.nroMatricula && <div className="input-invalid-disclaimer ">{errors.nroMatricula}</div>}
				</div>
				<div className="form-group"></div>
			</div>


		</>
	)
};


export default DatosProfesional;
