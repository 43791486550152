// src/components/Home.js
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loginRequest } from '../redux/actions/auth';
import './css/Home.css';
import backgroundImage from '../assets/microscope-background.png';
import Footer from "./Footer";
import { types } from '../redux/reducers/system';
import { connect } from "react-redux";
import { selectors as loginSelector } from "../redux/reducers/auth";
import {
    logout
} from '../redux/actions/auth';
import {
    clearSession
} from '../redux/reducers/sessionReducer';
import LoadingButton from './commons/LoadingButton';

const Home = ({ loading, login }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errors, setErrors] = useState({ username: '', password: '' });
    const dispatch = useDispatch();

    const handleLogin = () => {
        let hasErrors = false;
        const newErrors = { username: '', password: '' };

        if (!username) {
            newErrors.username = 'El nombre de usuario es requerido';
            hasErrors = true;
        }
        if (!password) {
            newErrors.password = 'La contraseña es requerida';
            hasErrors = true;
        }

        setErrors(newErrors);

        if (!hasErrors) {
            dispatch(loginRequest({ username, password }));
        }
    };

    useEffect(() => {
        dispatch({ type: types.GET_SYSTEM_DATA_REQUEST });
        dispatch(logout());
        dispatch(clearSession());
    }, [dispatch]);

    const { data, error } = useSelector(state => state.systemReducer);

    return (
        <div className="home-container">
            <div className="background-image" style={{ backgroundImage: `url(${backgroundImage})` }}>
                <div className="overlay">
                    <div className="content">
                        <h1 className="title-home">SiTRAPT</h1>
                        <h2 className="subtitle-home">Sistema de Trazabilidad de Anatomía Patológica</h2>
                        <div className="login-form">
                            {login && login.error && <div className="password-recovery-link"> {login.error} </div>}
                            <input
                                className={`input ${errors.username ? 'input-error' : ''}`}
                                type="text"
                                placeholder="Usuario"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            {errors.username && <div className="error-message">{errors.username}</div>}
                            <input
                                className={`input ${errors.password ? 'input-error' : ''}`}
                                type="password"
                                placeholder="Contraseña"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            {errors.password && <div className="error-message">{errors.password}</div>}
                            <LoadingButton
                                loading={loading}
                                onClick={handleLogin}
                            >
                                Iniciar sesión
                            </LoadingButton>
                        </div>
                    </div>
                </div>
            </div>
            <footer className="footer">
                {data &&
                    <Footer description={data.description} version={data.version} />
                }
                {error && <p>Error: {error}</p>}
            </footer>
        </div>
    );
};

const mapStateToProps = (state) => ({
    login: loginSelector.login(state),
    loading: loginSelector.loading(state),
});

export default connect(mapStateToProps)(Home);
