import React from 'react';
import Error from './Error';
import { permissionExists } from '../security/permissions'

const Security = ({ element: Component, permissions, requiredPermissions = [], ...rest }) => {

    const hasPermission = requiredPermissions.some(permission => permissionExists(permission, permissions));
     
    return hasPermission ? (
        <Component {...rest} />
    ) : (
        <Error message="No tiene permisos para ver esta pagina" />
    );
};

export default Security;
