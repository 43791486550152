export const types = {
  GET_HISTOGRAM_REQUEST: 'GET_HISTOGRAM_REQUEST',
  GET_HISTOGRAM_SUCCESS: 'GET_HISTOGRAM_SUCCESS',
  GET_HISTOGRAM_FAILURE: 'GET_HISTOGRAM_FAILURE',

  GET_STATS_REQUEST: 'GET_STATS_REQUEST',
  GET_STATS_SUCCESS: 'GET_STATS_SUCCESS',
  GET_STATS_FAILURE: 'GET_STATS_FAILURE',
}

const initialState = {
    loading: false,
    error: null,
    modalLoading: false,
    histograms: null,
    stats: null,
};

const metrics = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_HISTOGRAM_REQUEST:
            return {...state, loading: true, error: null };
        case types.GET_HISTOGRAM_SUCCESS:
            return {...state, loading: false, histograms: action.histograms, error: null,
            };
        case types.GET_HISTOGRAM_FAILURE:
            return {...state, loading: false, error: action.error, metrics: null };
          case types.GET_STATS_REQUEST:
            return {...state, loading: true, error: null };
        case types.GET_STATS_SUCCESS:
            return {...state, loading: false, stats: action.stats, error: null,
            };
        case types.GET_STATS_FAILURE:
            return {...state, loading: false, error: action.error, stats: null };    
        default:
            return state;
    }
};

export const getMetricsRequest = ( sampleData ) => ({
  type: types.GET_HISTOGRAM_REQUEST,
  sampleData,
  modalLoading: true,
});
  
export const getMetricsSuccess = (histograms) => ({
  type: types.GET_HISTOGRAM_SUCCESS,
  histograms,
  modalLoading: false,
});

export const getMetricsFailure = (code, msg) => ({
  type: types.GET_HISTOGRAM_FAILURE,
  error: { code, msg},
  modalLoading: false,
});

export const getStatsRequest = (query) => ({
  type: types.GET_STATS_REQUEST,
  modalLoading: true,
  query,
});
  
export const getStatsSuccess = (stats) => ({
  type: types.GET_STATS_SUCCESS,
  stats,
  modalLoading: false,
});

export const getStatsFailure = (code, msg) => ({
  type: types.GET_STATS_FAILURE,
  error: { code, msg},
  modalLoading: false,
});

export const selectors = {
    loading: state => state.metrics.loading,
    modalLoading: state => state.metrics.modalLoading,
    error: state => state.metrics.error,
    histograms: state => state.metrics.histograms,
    stats: state => state.metrics.stats,
};

export default metrics;


