// Coordinar con valores del backend

export const UserType = {
    ADMIN: { id: 'ADMIN', description: 'Administrador' },
    HISTOTECHNICIAN: { id: 'HISTOTECHNICIAN', description: 'Técnico histológico' },
    CITOTECHNICIAN: { id: 'CITOTECHNICIAN', description: 'Técnico citológico' },
    HEAD_OF_LABORATORY: { id: 'HEAD_OF_LABORATORY', description: 'Jefe de laboratorio' },
    RECEPTIONIST: { id: 'RECEPTIONIST', description: 'Recepcionista' },
    DOCTOR: { id: 'DOCTOR', description: 'Médico' }
};

export function getDescriptionById(userTypeId) {
    for (const key in UserType) {
        if (UserType[key].id === userTypeId) {
            return UserType[key].description;
        }
    }
    return 'Tipo desconocido';
}
