import React, {useState} from 'react';
import './css/registrarMuestra.css';

const DatosClinicos = ({formData, handleChange, diagnosticoClinico}) => {
	const [errors, setErrors] = useState({});

	const REQUIRED_ERROR_MESSAGE = "Este campo es obligatorio.";


	const handleBlur = (e) => {
		const {name, value} = e.target;
		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: !value ? REQUIRED_ERROR_MESSAGE : '',
		}));
	};

	const handleInvalid = (e) => {
		e.preventDefault();
		handleBlur(e);
		const firstInvalidElement = document.querySelector('.input-invalid');
		if (firstInvalidElement) {
			firstInvalidElement.scrollIntoView({behavior: 'smooth', block: 'center'});
		}
	};

	return (
		<>

			<h2>Datos clínicos</h2>

			<div className="input-row">
				<div className="form-group">
					<label>Diagnóstico clínico:</label>
					<input
						type="text"
						name="diagnosticoClinico"
						value={formData.diagnosticoClinico}
						onChange={handleChange}
						onInvalid={handleInvalid}
						onBlur={handleBlur}
						required
						className={errors.diagnosticoClinico ? 'input-invalid' : ''}
					/>
					{errors.diagnosticoClinico && <div className="input-invalid-disclaimer ">{errors.diagnosticoClinico}</div>}
				</div>
			</div>

			<div className="input-row">

				<div className="form-group">
					<label>Tratamiento local anterior (opcional):</label>
					<input
						type="text"
						name="tratamientoLocalAnterior"
						value={formData.tratamientoLocalAnterior}
						onChange={handleChange}
					/>
				</div>

				<div className="form-group">
					<label>Tiempo de evolución (opcional):</label>
					<input
						type="text"
						name="tiempoEvolucion"
						value={formData.tiempoEvolucion}
						onChange={handleChange}
					/>
				</div>
			</div>

			<div className="input-row">

				<div className="form-group">
					<label>Tiene biopsia anterior (opcional):</label>
					<select
						name="biopsiaAnterior"
						value={formData.biopsiaAnterior}
						onChange={handleChange}
					>
						<option key={"NO"} value={"NO"}>NO</option>
						<option key={"SI"} value={"SI"}>SI</option>

					</select>
				</div>

				<div className="form-group">
					{formData.biopsiaAnterior === 'SI' && (<div>
						<label>Resultado de biopsia anterior (opcional):</label>
					<input
						type="text"
						name="resultadoBiopsiaAnterior"
						value={formData.resultadoBiopsiaAnterior}
						onChange={handleChange}
					/></div>)}
				</div>
			</div>


		</>
	)
};


export default DatosClinicos;
