import {call, put, takeLatest} from 'redux-saga/effects';
import axios from 'axios';
import {
    types,
} from '../reducers/sessionReducer';
import {getSessionFailure, getSessionSuccess} from '../reducers/sessionReducer';

const baseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL;

function* sessionGet(sessionState) {

    console.log('sessionReducer:sessionGet(). Received params:', sessionState);
    if (!sessionState) {
        console.error('sessionReducer:sessionGet(). No sessionState received');
        return
    }
    if (!sessionState.session) {
        console.error('sessionReducer:sessionGet(). No sessionState.session received');
        return
    }

    const {username, accessToken} = sessionState.session;
    try {
        const cfg = {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        };
        const response = yield call(axios.post, `${baseUrl}/api/session`, {
            username, accessToken,
        }, cfg);
        yield put(getSessionSuccess(response.data));
    } catch (error) {
        yield put(getSessionFailure(error.code));
    }
}

function* updateSession(sessionState) {
    console.log('sessionReducer:updateSession(). Received params:', sessionState);
    if (!sessionState) {
        console.error('sessionReducer:updateSession(). No sessionState received');
        return
    }

    if (!sessionState.data) {
        console.error('sessionReducer:updateSession(). No sessionState.data received');
        return
    }
    let username;
    let accessToken;
    let refreshToken;
    if (!sessionState.data.session) {
        username = sessionState.data.username;
        accessToken = sessionState.data.accessToken;
        refreshToken = sessionState.data.refreshToken;
    } else {
        username = sessionState.data.session.username;
        accessToken = sessionState.data.session.accessToken;
        refreshToken = sessionState.data.session.refreshToken;
    }

    try {
        const cfg = {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
            },
        };

        const response = yield call(axios.post, `${baseUrl}/api/updateSession`, {
            username, accessToken, refreshToken
        }, cfg);

        console.log('/api/updateSession response: ' + response.status);


        const updatedResponse = {
            ...response.data,
            userType: sessionState.data.userType
        };

			console.log(updatedResponse);
        yield put(getSessionSuccess(updatedResponse));

    } catch (error) {
        console.error('/api/updateSession response:', error);
        yield put(getSessionFailure(error.code));
    }
}

export function* watchSessionSaga() {
    yield takeLatest(types.SESSION_REQUEST, sessionGet);
    yield takeLatest(types.UPDATE_SESSION, updateSession);
}
