import React from 'react';
import { connect } from 'react-redux';
import './css/parametrizacion.css';
import './commons/css/lines.css';
import { translate } from '../utils/translate';
import { formatDate } from '../utils/dates';


const SampleDetail = ({ selectedSample }) => {

  return (
    <div className="patient-info">
    <h3>Detalle de la muestra</h3>
    <div className="patient-info">
      <p><strong>Número de protocolo:</strong> {selectedSample.protocolNumber}</p>
      <p><strong>Estado:</strong> {translate(selectedSample.status)}</p>
      <p><strong>Tipo:</strong> {translate(selectedSample.type)}</p>
      <p><strong>Subtipo:</strong> {translate(selectedSample.subtype)}</p>
      <p><strong>Fecha de ingreso:</strong> {formatDate(selectedSample.creationDate)}</p>
    </div>
			<div className="patient-info">
				<h3>Información del paciente</h3>
				<p><strong>Nombre y apellido:</strong> {selectedSample.patientName}</p>
				<p><strong>Número de documento:</strong> {selectedSample.patientDNI}</p>
				<p><strong>Fecha de nacimiento:</strong> {formatDate(selectedSample.patientBirthdate)}</p>
				<p><strong>Email:</strong> {selectedSample.patientEmail}</p>
				<strong>Número de historia clínica:</strong> {selectedSample.clinicHistoryNumber}
			</div>
			<div className="hospital-info">
				<h3>Hospital</h3>
				<p><strong>Nombre:</strong> {selectedSample.hospitalData.hospitalName}</p>
				{selectedSample.hospitalData.hospitalRoom &&  <p><strong>Habitación:</strong> {selectedSample.hospitalData.hospitalRoom}</p>}
				{selectedSample.hospitalData.bed && <p><strong>Cama:</strong> {selectedSample.hospitalData.bed}</p>}
    </div>
  </div>
  );
};

export default connect(undefined)(SampleDetail);
