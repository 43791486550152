// src/redux/reducers/index.js
import { combineReducers } from 'redux';
import systemReducer from './system';
import authReducer from './auth';
import sessionReducer from './sessionReducer';
import entity from './entity';
import user from './user';
import sample from './sample';
import professional from './professional';
import metrics from './metrics';
import { connectRouter } from 'connected-react-router';

const rootReducer = (history) => combineReducers({
    router: connectRouter(history), 
    systemReducer: systemReducer,
    authReducer: authReducer,
    session: sessionReducer,
    entity: entity,
    user: user,
    sample: sample,
    professional: professional,
    metrics: metrics,
});

export default rootReducer;
