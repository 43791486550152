export const types = {
  LIST_USERS_REQUEST: 'LIST_USERS_REQUEST',
  LIST_USERS_SUCCESS: 'LIST_USERS_SUCCESS',
  LIST_USERS_FAILURE: 'LIST_USERS_FAILURE',

  DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

  ADD_USER_REQUEST: 'ADD_USER_REQUEST',
  ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
  ADD_USER_FAILURE: 'ADD_USER_FAILURE',

  SET_USER_REQUEST: 'SET_USER_REQUEST',

  EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
  EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
  EDIT_USER_FAILURE: 'EDIT_USER_FAILURE',
}

const createInitialState = () => {
  
      return {
          // Flag para mostrar loading del content wrapper
          loading: false,
          // Flag para mostrar loading de modal
          modalLoading: false,
          error: {
              code: null,
              msg: null
          },
          users: [],
          selectedUser: undefined,
      };
  };

const initialState = createInitialState();

const user = (state = initialState, action) => {
    switch (action.type) {
        case types.LIST_USERS_REQUEST:
            return {...state, loading: true, error: null};
        case types.LIST_USERS_SUCCESS:
            return {...state, loading: false, users: action.users     
            };
        case types.LIST_USERS_FAILURE:
            return {...state, loading: false, error: action.error, users: []};
        case types.DELETE_USER_REQUEST:
            return {...state, modalLoading: true };
        case types.DELETE_USER_SUCCESS:
            return {...state, modalLoading: false , error: null};
        case types.DELETE_USER_FAILURE:
            return {...state, modalLoading: false, error: action.error};
        case types.ADD_USER_REQUEST:
            return {...state, modalLoading: true };
        case types.ADD_USER_SUCCESS:
            return {...state, modalLoading: false , error: null};
        case types.ADD_USER_FAILURE:
            return {...state, modalLoading: false, error: action.error, loading: false, users: []};
        case types.EDIT_USER_REQUEST:
            return {...state, modalLoading: true };
        case types.EDIT_USER_SUCCESS:
            return {...state, modalLoading: false , error: null};
        case types.EDIT_USER_FAILURE:
            return {...state, modalLoading: false, error: action.error};       
        case types.SET_USER_REQUEST:
            return {...state, selectedUser : action.user};
        default:
            return state;
    }
};

export const getUsers = () => ({
  type: types.LIST_USERS_REQUEST,
  loading: true,
});
  
export const getUsersSuccess = (users) => ({
  type: types.LIST_USERS_SUCCESS,
  users,
  loading: false,
});
  
export const getUsersFailure = (code, msg) => ({
  type: types.LIST_USERS_FAILURE,
  // Show indica que debe mostrarse el error como notificacion, ya que el modal tambien usa los estados de error
  error: { code, msg, show: true },
  loading: false,
});

export const deleteUserRequest = (userId) => ({
  type: types.DELETE_USER_REQUEST,
  userId,
  loading: true,
});

export const deleteUserSuccess = () => ({
  type: types.DELETE_USER_SUCCESS,
  loading: false,
});

export const deleteUserFailure = (code, msg) => ({
  type: types.DELETE_USER_FAILURE,
  error: { code, msg },
  loading: false,
});

export const addUserRequest = (user) => ({
  type: types.ADD_USER_REQUEST,
  user,
  modalLoading: true,
});

export const addUserSuccess = () => ({
  type: types.ADD_USER_SUCCESS,
  modalLoading: false,
});

export const addUserFailure = (code, msg) => ({
  type: types.ADD_USER_FAILURE,
  error: { code, msg },
  modalLoading: false,
});

export const editUserRequest = (user) => ({
  type: types.EDIT_USER_REQUEST,
  user,
  modalLoading: true,
});

export const editUserSuccess = () => ({
  type: types.EDIT_USER_SUCCESS,
  modalLoading: false,
});

export const editUserFailure = (code, msg) => ({
  type: types.EDIT_USER_FAILURE,
  error: { code, msg },
  modalLoading: false,
});

export const setUser = (user) => ({
  type: types.SET_USER_REQUEST,
  user,
});

export const selectors = {
  getUsers: (state) => state.user.users,
  loading: state => state.user.loading,
  modalLoading: state => state.user.modalLoading,
  error: state => state.user.error,
  getSelectedUser: state => state.user.selectedUser,
};

export default user;


