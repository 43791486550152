import React from 'react';
import {useDispatch, connect} from 'react-redux';
import '../css/parametrizacion.css';
import '../commons/css/lines.css';
import {useNavigate} from 'react-router-dom';
import { selectors, setSelectedSample } from '../../redux/reducers/sample';
import './css/pagination.css';
import SampleManagement from './SampleManagement';

const SampleRegistrarUbicacion = ({loading, error, step}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRegisterLocation = (sample) => {
    dispatch(setSelectedSample(sample));
    navigate('/registrarUbicacionMuestra');
  };

  const actions = [
    { name: 'Registrar', function: handleRegisterLocation },
  ];

  return (
    <SampleManagement directTitle="Registrar ubicación de muestra" loading={loading} error={error} directActions={actions} step={step}>
    </SampleManagement>
  );
};

const mapStateToProps = (state) => ({
    samples: selectors.samples(state),
    loading: selectors.loading(state),
    error: selectors.error(state),
    session: selectors.session(state)
});

export default connect(mapStateToProps)(SampleRegistrarUbicacion);
