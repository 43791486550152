import { Outlet, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectors } from './redux/reducers/sessionReducer';

export const PrivateRoutes = ({ session }) => {
    return session.username ? <Outlet /> : <Navigate to="/" />;
};

const mapStateToProps = (state) => ({
    session: selectors.session(state),
});

export default connect(mapStateToProps)(PrivateRoutes);
