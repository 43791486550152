export const translate = (text) => {
	const translations = {
		"FREEZING": "CONGELACIÓN",
		"REGULAR": "REGULAR",
		"LIQUID": "LIQUIDO",
		"PAP": "PAP",
		"PUNCTURE": "PUNCIÓN",
		"TODO": "PENDIENTE",
		//
		"IN_PROGRESS": "EN PROGRESO",
		"DONE": "HECHO",
		//
		"HISTOLOGIC": "HISTOLÓGICA",
		"CITOLOGIC": "CITOLÓGICA",
		//
		"ASSIGN_DOCTOR": "ASIGNAR MÉDICO",
		"MACROSCOPY": "MACROSCOPÍA",
		"ASSIGN_TECHNICIAN": "ASIGNAR TÉCNICO",
		"HISTOLOGY_PROCESS": "PROCESO HISTOLÓGICO",
		"DIAGNOSTIC": "DIAGNÓSTICO",
		"GENERATE_REPORT": "GENERAR INFORME",
		"SEND_REPORT": "ENVIAR INFORME",
		"CENTRIFUGATE": "CENTRIFUGADO",
		"FIXATION": "PROCESAMIENTO",
		"COLORATION": "COLORACIÓN",
		"COLORATION_PROCESS": "PROCESO DE COLORACIÓN",
		"INCLUSION": "INCLUSIÓN",
		"ARCHIVE_SAMPLES": "ARCHIVADO DE MUESTRAS",
		"MICROTOME_CUT": "CORTES EN MICRÓTOMO",
		"CRIOSTATE_CUT": "CORTES EN CRIOSTATO",
		"HISTOTECHNICIAN": "técnico histológico",
		"CITOTECHNICIAN": "técnico citológico",
		"DOCTOR": "médico",
	}
	return translations[text];
}
