import React from 'react';
import './css/notification.css';

const Notification = ({ message, type, show}) => {
  if (!message) return null;

  // Si show es false quiere decir que es una operacion que muestra un modal
  // Para los casos de listados, en caso de error se muestra el error en la barra de notificacion
  if (!show) return null;
  return (
    <div className="notification-container">
      <div className={`notification ${type}`}>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Notification;