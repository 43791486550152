import React, {useState} from 'react';
import './css/registrarMuestra.css';

const MaterialRemitido = ({formData, handleChange}) => {
	const [errors, setErrors] = useState({});

	const REQUIRED_ERROR_MESSAGE = "Este campo es obligatorio.";


	const handleBlur = (e) => {
		const {name, value} = e.target;
		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: !value ? REQUIRED_ERROR_MESSAGE : '',
		}));
	};

	const handleInvalid = (e) => {
		e.preventDefault();
		handleBlur(e);
		const firstInvalidElement = document.querySelector('.input-invalid');
		if (firstInvalidElement) {
			firstInvalidElement.scrollIntoView({behavior: 'smooth', block: 'center'});
		}
	};
	return (
		<>

			<h2>Datos del material remitido</h2>

			<div className="form-group">
				<label>Material remitido:</label>
				<input
					type="text"
					name="materialRemitido"
					value={formData.materialRemitido}
					onChange={handleChange}
					onInvalid={handleInvalid}
					onBlur={handleBlur}
					required
					className={errors.materialRemitido ? 'input-invalid' : ''}
				/>
				{errors.materialRemitido &&
					<div className="input-invalid-disclaimer ">{errors.materialRemitido}</div>}
			</div>
			<div className="input-row">

				<div className="form-group">
					<label>Sitio de toma de muestra:</label>
					<input
						type="text"
						name="sitioTomaMuestra"
						value={formData.sitioTomaMuestra}
						onChange={handleChange}
						onInvalid={handleInvalid}
						onBlur={handleBlur}
						required
						className={errors.sitioTomaMuestra ? 'input-invalid' : ''}
					/>
					{errors.sitioTomaMuestra &&
						<div className="input-invalid-disclaimer ">{errors.sitioTomaMuestra}</div>}
				</div>

				<div className="form-group">
					<label>Método de obtención de la muestra:</label>
					<input
						type="text"
						name="metodoObtencionMuestra"
						value={formData.metodoObtencionMuestra}
						onChange={handleChange}
						onInvalid={handleInvalid}
						onBlur={handleBlur}
						required
						className={errors.metodoObtencionMuestra ? 'input-invalid' : ''}
					/>
					{errors.metodoObtencionMuestra &&
						<div className="input-invalid-disclaimer ">{errors.metodoObtencionMuestra}</div>}
				</div>
			</div>


		</>
	)
};


export default MaterialRemitido;
