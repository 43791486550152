import React from 'react';

const ReferencesModalDiagram = ({ closeReference }) => {
  return (
    <div style={{
      position: 'absolute',
      top: 200,
      left: 200,
      width: '30vw',
      height: '35vh',
      backgroundColor: 'white', // Cambiar a 'transparent' para que se vea el borde
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
      border: "1px solid #ccc"
    }}>
      <div style={{
        background: '#fff',
        padding: '20px',
        borderRadius: '8px',
        width: '100%',
        height: '80%', // Ajustar a 100% para ocupar toda la altura del contenedor
        position: 'relative',
      }}>
         
        <div style={{ display: 'flex', height: '20%', width: '100%', marginBottom: '10px' }}>
          <h4>Referencias</h4>
        </div>
        &nbsp;
        <div style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          marginBottom: '20px'
        }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{
              width: '20px',
              height: '20px',
              backgroundColor: '#ffccd5',  // Color rosado para "Pendiente"
              marginRight: '8px',
              borderRadius: '4px'
            }}></div>
            <span>Pendiente</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{
              width: '20px',
              height: '20px',
              backgroundColor: '#ffab6c',  // Color naranja para "En proceso"
              marginRight: '8px',
              borderRadius: '4px'
            }}></div>
            <span>En progreso</span>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{
              width: '20px',
              height: '20px',
              backgroundColor: '#9dd696',  // Color verde para "Listo"
              marginRight: '8px',
              borderRadius: '4px'
            }}></div>
            <span>Listo</span>
          </div>
        </div>

        <div style={{
          fontSize: '0.9em',
          textAlign: 'center',
          color: '#666',
          marginBottom: '20px'
        }}>
              Para el proceso histológico, es posible entrar al detalle del proceso haciendo clic en la caja del paso correspondiente. <br/><br/>
          Si el paso está completo, se puede ver información de trazabilidad acercando el mouse sobre su caja.
        </div>

        <div style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
          <button className="close-button" onClick={closeReference}>Cerrar</button>
        </div>
      </div>
    </div>
  );
};

export default ReferencesModalDiagram;
