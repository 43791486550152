import React, { useState, useEffect } from 'react';
import {useDispatch, connect} from 'react-redux';
import '../css/parametrizacion.css';
import '../commons/css/lines.css';
import {useNavigate} from 'react-router-dom';
import { selectors, setSelectedSample, getAllSamplesRequest } from '../../redux/reducers/sample';
import './css/pagination.css';
import SampleManagement from './SampleManagement';
import { getSamplesWithReportCompleted } from '../../utils/sample';

const SampleImprimirInforme = ({loading, error, step, samplesAll}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [samples, setSamples] = useState(null);

  const handleSendInforme = (sample) => {
    dispatch(setSelectedSample(sample));
    navigate('/imprInforme');
  };

  useEffect(() => {
    dispatch(getAllSamplesRequest());
  }, []);

  useEffect(() => {
    if (samplesAll)
      setSamples(getSamplesWithReportCompleted(samplesAll));
  }, [samplesAll]);

  const actions = [
    { name: 'Imprimir', function: handleSendInforme },
  ];
  
  return (
    <SampleManagement directTitle="Imprimir informe final" loading={loading} error={error} directActions={actions} step={step} directSamples={samples}>
    </SampleManagement>
  );
};

const mapStateToProps = (state) => ({
    samplesAll: selectors.samplesAll(state),
    loading: selectors.loading(state),
    error: selectors.error(state),
    session: selectors.session(state)
});

export default connect(mapStateToProps)(SampleImprimirInforme);
