import React, { useEffect, useRef } from 'react';
import { getDocument } from 'pdfjs-dist/webpack';


const PdfViewer = ({ base64Data, scale = 1, ismacroscopy }) => {
	const containerRef = useRef(null);

	useEffect(() => {
		const renderPdf = async () => {
			const pdfData = atob(base64Data);
			const pdfUint8Array = new Uint8Array(pdfData.length);
			for (let i = 0; i < pdfData.length; i++) {
				pdfUint8Array[i] = pdfData.charCodeAt(i);
			}

			const pdf = await getDocument({ data: pdfUint8Array }).promise;
			const numPages = pdf.numPages;

			// Limpiamos el contenedor antes de renderizar
			containerRef.current.innerHTML = "";

			for (let pageNum = 1; pageNum <= numPages; pageNum++) {
				const page = await pdf.getPage(pageNum);
				const viewport = page.getViewport({ scale });

				// Crear un nuevo canvas para cada página
				const canvas = document.createElement("canvas");
				const context = canvas.getContext("2d");
				canvas.width = viewport.width;
				canvas.height = viewport.height;

				const renderContext = {
					canvasContext: context,
					viewport: viewport,
				};

				// Renderizar la página en el canvas
				await page.render(renderContext).promise;

				canvas.style.width = '800px';
				canvas.style.height = 'auto';

				// Añadir el canvas al contenedor
				containerRef.current.appendChild(canvas);
			}
		};

		renderPdf();
	}, [base64Data, scale]);

	return (
		<div ref={containerRef} style={{ width: 'auto', height: 'auto', overflow: 'auto' }}></div>
	);
};

export default PdfViewer;
