import {call, put, takeLatest, delay} from 'redux-saga/effects';
import axios from 'axios';
import {
	entityType,
	getEntityFailure,
	getEntitySuccess,
	types,
} from '../reducers/entity';
import {getRequestWithToken} from '../../security/api';

const baseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL;

function* getEntity({entityTypeList}) {
	try {

		// delay agregado para simular retardo
		yield delay(500);

		for (let i = 0; i < entityTypeList.length; i++) {

			if (!entityTypeList[i]) {
				continue;
			}

			if (entityTypeList[i] === 'hospital') {
				const hospitalResponse = yield getRequestWithToken(`${baseUrl}/api/hospitals`);
				let entities = hospitalResponse.data.map((hospital, index) => ({
					id: index + 1,
					name: hospital
				}));
				yield put(getEntitySuccess(entityType.HOSPITAL, entities));
			}

			if (entityTypeList[i] === 'diagnosticoClinico') {
				let entities = [
					{
						id: 1,
						name: 'Sarna'
					},
					{
						id: 2,
						name: 'Alopecia'
					},
					{
						id: 3,
						name: 'Astigmatismo'
					}];
				yield put(getEntitySuccess(entityType.DIAGNOSTICO_CLINICO, entities));
			}
		}
	} catch
		(error) {
		const msg = error.code != 0 ? "Se ha producido un error cargando datos del sistema" : error.msg;
		yield put(getEntityFailure(error.code, msg));
	}
}

export function* watchEntitySaga() {
	yield takeLatest(types.GET_ENTITY_REQUEST, getEntity);
}
