import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaUserCog } from 'react-icons/fa';

const CardResult = ({ dashresult, field, label, link, icon, tooltip }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  let timeoutId = null;

  const handleMouseEnter = () => {
    timeoutId = setTimeout(() => {
      setShowTooltip(true);
    }, 800);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutId);
    setShowTooltip(false);
  };

  return (
    <div
      className="stat-card transparent"
      style={{ position: 'relative' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`stat-icon ${icon}`}>
        <FaUserCog size={30} />
      </div>
      <div className="stat-info">
        <h3>{label}</h3>
        {dashresult && field !== 0 ? (
          link ? (
            <Link to={`${link}`}>
              {field} tareas
            </Link>
          ) : (
            <span>{field ? field : ''} tareas</span>
          )
        ) : (
          <b>Sin tareas</b>
        )}
      </div>

      {tooltip && showTooltip && (
        <div 
          className="tooltip" 
          style={{
            position: 'absolute',
            bottom: '100%',
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: '#FFF9C4', 
            color: 'black',
            padding: '8px',
            borderRadius: '4px',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
            fontSize: '14px',
            whiteSpace: 'pre-wrap', 
            lineHeight: '1.4',
            textAlign: 'center',
            zIndex: 10,
            width: 'auto',
            maxWidth: '200px', 
            wordWrap: 'break-word', 
          }}
        >
          {tooltip}
        </div>
      )}
    </div>
  );
};

export default CardResult;
