import React, { useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { selectors } from '../../redux/reducers/user';
import ContentWrapper from '../commons/ContentWrapper';
import { addUserRequest } from '../../redux/reducers/user';
import Modal from '../commons/Modal';
import { UserType } from '../../constants';
import { useNavigate } from 'react-router-dom';

const CreateUser = ({ modalLoading, error }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
	const [errors, setErrors] = useState({});

  const [user, setUser] = useState({
    username: '',
    password: '',
    email: '',
    type: ''
  });

	const REQUIRED_ERROR_MESSAGE = "Este campo es obligatorio.";

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  };

	const handleUsernameChange = (e) => {
		const{value} = e.target;
		handleChange(e);

		if (value && /\s/.test(value)) {
			setErrors((prevErrors) => ({
				...prevErrors,
				username: 'El nombre de usuario no debe contener espacios',
			}));
		} else if (!value) {
			setErrors((prevErrors) => ({
				...prevErrors,
				username: REQUIRED_ERROR_MESSAGE,
			}));
		} else {
			setErrors((prevErrors) => ({...prevErrors, username: ''}));
		}
	}

	const handleEmailChange = (e) => {
		const {value} = e.target;
		handleChange(e); // Llama a la función handleChange para actualizar el estado

		// Validar el DNI y establecer el mensaje de error
		if (value && !validateEmail(value)) {
			setErrors((prevErrors) => ({
				...prevErrors,
				email: 'El formato ingresado no es válido.',
			}));
		} else if (!value) {
			setErrors((prevErrors) => ({
				...prevErrors,
				email: REQUIRED_ERROR_MESSAGE,
			}));
		} else {
			setErrors((prevErrors) => ({...prevErrors, email: ''}));
		}
	};

	const handleBlur = (e) => {
		const {name, value} = e.target;
		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: !value ? REQUIRED_ERROR_MESSAGE : '',
		}));
	};

	const handleInvalid = (e) => {
		const {name} = e.target;
		e.preventDefault();
		if (name === 'username'){
			handleUsernameChange(e)
		}
		else if (name === 'email'){
			handleEmailChange(e)
		}
		else{
		handleBlur(e);
		}
		const firstInvalidElement = document.querySelector('.input-invalid');
		if (firstInvalidElement) {
			firstInvalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	};

  const handleSubmit = (e) => {
    e.preventDefault();
		setShowModal(true);
		dispatch(addUserRequest(user));
		setUser({
			username: '',
			password: '',
			fullName: '',
			email: '',
			type: '',
			registrationNumber: ''
		});
  };

	const validateEmail = (email) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

  const handleConfirm = (e) => {
    e.preventDefault();
    setShowModal(false);
    navigate('/gestionUsuarios');
  };

  return (
		<ContentWrapper title="Crear usuario" error={error}>
			<form onSubmit={handleSubmit} className="user-form">
				<div className="input-row">
					<div className="form-group">
						<label htmlFor="username">Nombre de usuario:</label>
						<input
							type="text"
							id="username"
							name="username"
							value={user.username}
							onChange={handleUsernameChange}
							onInvalid={handleInvalid}
							onBlur={handleUsernameChange}
							required
							pattern="^\S+$"
							className={errors.username ? 'input-invalid' : ''}
						/>
						{errors.username && <div className="input-invalid-disclaimer ">{errors.username}</div>}

					</div>
					<div className="form-group"></div>
				</div>
				<div className="input-row">
					<div className="form-group">
						<label htmlFor="fullName">Nombre y apellido:</label>
						<input
							type="text"
							id="fullName"
							name="fullName"
							value={user.fullName}
							onChange={handleChange}
							onInvalid={handleInvalid}
							onBlur={handleBlur}
							required
							className={errors.fullName ? 'input-invalid' : ''}
						/>
						{errors.fullName && <div className="input-invalid-disclaimer ">{errors.fullName}</div>}
					</div>
					<div className="form-group"></div>
				</div>
				<div className="input-row">
					<div className="form-group">
						<label htmlFor="registrationNumber">Número de matrícula (opcional):</label>
						<input
							type="text"
							id="registrationNumber"
							name="registrationNumber"
							value={user.registrationNumber}
							onChange={handleChange}
							onInvalid={handleInvalid}
							onBlur={handleBlur}
							className={errors.registrationNumber ? 'input-invalid' : ''}
						/>
						{errors.registrationNumber && <div className="input-invalid-disclaimer ">{errors.registrationNumber}</div>}
					</div>
					<div className="form-group"></div>
				</div>
				<div className="input-row">
					<div className="form-group">
						<label htmlFor="password">Contraseña:</label>
						<input
							type="password"
							id="password"
							name="password"
							value={user.password}
							onChange={handleChange}
							onInvalid={handleInvalid}
							onBlur={handleBlur}
							required
							className={errors.password ? 'input-invalid' : ''}
						/>
						{errors.password && <div className="input-invalid-disclaimer ">{errors.password}</div>}

					</div>
					<div className="form-group"></div>
				</div>
				<div className="input-row">

					<div className="form-group">
						<label htmlFor="email">Correo electrónico:</label>
						<input
							type="email"
							id="email"
							name="email"
							value={user.email}
							onChange={handleEmailChange}
							onInvalid={handleInvalid}
							required
							pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
							onBlur={handleEmailChange}
							className={errors.email ? 'input-invalid' : ''}
						/>
						{errors.email && <div className="input-invalid-disclaimer ">{errors.email}</div>}

					</div>
					<div className="form-group"></div>
				</div>
				<div className="input-row">
					<div className="form-group">
						<label htmlFor="type">Tipo de usuario:</label>
						<select
							id="type"
							name="type"
							value={user.type}
							onChange={handleChange}
							required
							onBlur={handleBlur}
							onInvalid={handleInvalid}
							className={errors.estadoCivil ? 'input-invalid' : ''}
						>
							<option value="" disabled>Seleccione un tipo</option>
							{Object.values(UserType).map(({id, description}) => (
								<option key={id} value={id}>{description}</option>
							))}
						</select>
						{errors.type && <div className="input-invalid-disclaimer ">{errors.type}</div>}
					</div>
					<div className="form-group"></div>
				</div>
				<button type="submit" className="submit-button">Crear</button>
			</form>
			{showModal && (
				<Modal
					modalLoading={modalLoading}
					error={error}
					onClose={(e) => handleConfirm(e)}
				/>
			)}
		</ContentWrapper>
	);
};

const mapStateToProps = (state) => ({
	modalLoading: selectors.modalLoading(state),
	error: selectors.error(state),
});

export default connect(mapStateToProps)(CreateUser);
