//import React, {useEffect} from 'react';
import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {connect, useDispatch} from 'react-redux';
//import {getSession, putSession, selectors} from "../redux/reducers/sessionReducer"
import {putSession, selectors} from "../redux/reducers/sessionReducer"
import debounce from 'lodash.debounce';

const SessionController = ({session}) => {
    const dispatch = useDispatch();
    const location = useLocation();
    let lastRefresh = new Date();

    useEffect(() => {

        let lastRefresh = new Date();

        //if (session.username != null && location.pathname !== '/') {
        if (session && session.username != null) {
            const fetchDataInterval = () => {
                if (new Date() - lastRefresh > 60000) { // refrescar la sesion cada 1 minuto
                    lastRefresh = new Date();
                    dispatch(putSession(session));
                }
            };

            fetchDataInterval();

            const intervalId = setInterval(fetchDataInterval, 10000);

            return () => clearInterval(intervalId);
        }
    }, [dispatch, location.pathname, session]);


    const handleActivity = debounce(() => {
        //if (session.username != null && location.pathname !== '/') dispatch(putSession(session));
        if (session && session.username != null) {
            if (new Date() - lastRefresh > 60000) { // refrescar la sesion cada 1 minuto
                lastRefresh = new Date();
                dispatch(putSession(session));
            }
        }
    }, 5000);

    useEffect(() => {
        if (session.username != null && location.pathname !== '/') {
            document.addEventListener('mousemove', handleActivity);
            document.addEventListener('click', handleActivity);
            document.addEventListener('keydown', handleActivity);

            return () => {
                document.removeEventListener('mousemove', handleActivity);
                document.removeEventListener('click', handleActivity);
                document.removeEventListener('keydown', handleActivity);
            };
        }
    }, [dispatch, location.pathname, session, handleActivity]);


    return null;
};
const mapStateToProps = (state) => ({
    session: selectors.session(state),
});

export default connect(mapStateToProps)(SessionController);
