import React, { useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import '../css/parametrizacion.css';
import UserList from './UserList'
import {
  getUsers, selectors
} from '../../redux/reducers/user';
import ContentWrapper from '../commons/ContentWrapper';
import '../commons/css/lines.css';
import { useNavigate } from 'react-router-dom';

const UserManagement = ({ loading, error, users }) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();


  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const handleCreateUserClick = () => {
    navigate('/crearUsuario');
  };

  const onBack = () => {
    navigate('/dashboard');
  };

  return (
    <ContentWrapper title="Gestión de usuarios" loading={loading} error={error} onBack={onBack}>
      <p>
      {users && users.length} usuarios encontrados. Para crear un nuevo usuario, haga click aquí:
        <button className="edit-button" onClick={handleCreateUserClick}>Nuevo usuario</button>
      </p>
      {users && (
            <UserList users={users} />
          )}

    </ContentWrapper>
  );
};


const mapStateToProps = (state) => ({
    users: selectors.getUsers(state),
    loading: selectors.loading(state),
    error: selectors.error(state),
});

export default connect(mapStateToProps)(UserManagement);
