import {call, put, takeLatest, delay} from 'redux-saga/effects';
import axios from 'axios';
//import CryptoJS from 'crypto-js';
import history from '../../history';  // Import history

import {
    LOGIN_REQUEST,
    loginSuccess,
    loginFailure,
} from '../actions/auth';

import {
    putSession
} from '../reducers/sessionReducer';
import {getPermissionsByValues} from '../../security/permissions';

const baseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL;

function* login(action) {

    const {username, password} = action.payload.username;

    //const hashCredential_ = CryptoJS.SHA512(username + password).toString();
    //const hashCredential = hashCredential_.toString(CryptoJS.enc.Hex);

    try {
        const response = yield call(axios.post, `${baseUrl}/api/login`, {
            password,
            username,
        });

        const loginResponse = response.data;
        
        const cfg = {
            headers: {
                'Authorization': `Bearer ${loginResponse.accessToken}`,
            },
        };
        const responsePermissions = yield call(axios.get, `${baseUrl}/api/permissions`, cfg);

       

        loginResponse.functions = getPermissionsByValues(responsePermissions['data'][loginResponse.userType]);

        yield put(loginSuccess(loginResponse));
        yield put(putSession(loginResponse));
        window.location.href="/dashboard";

    } catch (error) {
        if (error.response) {
            yield put(loginFailure(error.response.data.message));
        } else if (error.request) {
            yield put(loginFailure("No se recibio respuesta del servidor"));
        } else {
            yield put(loginFailure("Error al configurar la solicitud"));
        }
    }
}

export function* watchLoginSaga() {
    yield takeLatest(LOGIN_REQUEST, login);
}
