import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
} from '../actions/auth';

const initialState = {
    data: null,
    loading: false,
    error: null,
    functions: [],
};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {...state, loading: true, error: null};
        case LOGIN_SUCCESS:
            return {...state, loading: false, data: action.data};
        case LOGIN_FAILURE:
            return {...state, loading: false, error: action.payload};
        case LOGOUT:
            return initialState;
        default:
            return state;
    }
};

export const logout = () => ({
    type: LOGOUT,
});

export const selectors = {
    login: state => state.authReducer,
    loading: state => state.authReducer.loading,
};

export default authReducer;


