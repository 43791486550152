export const types = {
    GET_ENTITY_REQUEST: 'GET_ENTITY_REQUEST',
    GET_ENTITY_SUCCESS: 'GET_ENTITY_SUCCESS',
    GET_ENTITY_FAILURE: 'GET_ENTITY_FAILURE',
}

// agregar un nuevo tipo a este array cuando se agrege uno nuevo en el backend
export const entityType = {
    HOSPITAL: 'hospital',
    DIAGNOSTICO_CLINICO: 'diagnosticoClinico',
}

const createInitialState = () => {
    const initialEntities = Object.values(entityType).reduce((acc, type) => {
        acc[type] = [];
        return acc;
    }, {});

    return {
        loading: false,
        error: {
            code: null,
            msg: null
        },
        entities: initialEntities
    };
};

const initialState = createInitialState();

const entity = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_ENTITY_REQUEST:
            return {...state, loading: true, error: null};
        case types.GET_ENTITY_SUCCESS:
            return {...state, loading: false, entities: {...state.entities, ...action.entities}};
        case types.GET_ENTITY_FAILURE:
            return {...state, loading: false, error: action.error};
        default:
            return state;
    }
};

export const getEntity = (entityTypeList) => ({
    type: types.GET_ENTITY_REQUEST,
    entityTypeList: entityTypeList,
    loading: true,
});

export const getEntitySuccess = (entityType, entities) => {
    return {
        type: types.GET_ENTITY_SUCCESS,
        entities: {
            [entityType]: entities,
        },
        loading: false,
    };
};

export const getEntityFailure = (code, msg) => {
    return {
        type: types.GET_ENTITY_FAILURE,
        error: {code, msg},
        loading: false,
    };
};

export const selectors = {
    entities: (state, entityType) => {
        if (entityType && state.entity && state.entity.entities && state.entity.entities[entityType]) {
            return state.entity.entities[entityType];
        } else {
            return []
        }
    },
    loading: state => state.entity.loading,
    error: state => state.entity.error,
};

export default entity;


