import React, {useEffect, useState} from 'react';
import './css/registrarMuestra.css';
import Select from 'react-select';

const Hospital = ({formData, handleChange, hospitales, handleSelectChange}) => {
	const options = hospitales && hospitales.map(hospital => ({
		value: hospital.id, label: hospital.name, target: {
			name: hospital.name,
			value: hospital.id,
		}
	}));

	return (
		<>
			<h2>Datos de Institución</h2>
			<div className="input-row">
				<div className="form-group">
					<label>Hospital solicitante:</label>
					<Select
						name="hospitalSolicitante"
						value={options.find(option => option.target.name === formData.hospitalSolicitante)}
						onChange={handleSelectChange}
						options={options}
						placeholder="Seleccionar hospital"
					/>
				</div>
				<div className="form-group"></div>
			</div>

			<div className="input-row">

				<div className="form-group">
					<label>Sala (opcional):</label>
					<input
						type="text"
						name="sala"
						value={formData.sala}
						onChange={handleChange}
					/>
				</div>

				<div className="form-group">
					<label>Cama (opcional):</label>
					<input
						type="text"
						name="cama"
						value={formData.cama}
						onChange={handleChange}
					/>
				</div>
			</div>

		</>
	)
};


export default Hospital;
