import React from 'react';
import './css/loadingbutton.css';

const LoadingButton = ({ loading, onClick, children, ...props }) => {
    return (
        <button 
            className={`button-with-loading-state ${loading ? 'loading' : ''}`}
            onClick={onClick}
            disabled={loading}
            {...props}
        >
            <span className={`button-text ${loading ? 'loading' : ''}`}>
                {loading ? 'Cargando...' : children}
            </span>
        </button>
    );
};

export default LoadingButton;