import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import './css/registrarMuestra.css';
import ContentWrapper from '../commons/ContentWrapper';

import {
  entityType, selectors
} from '../../redux/reducers/entity';
import {
  selectors as sampleSelectors
} from '../../redux/reducers/sample';
import { getDelayedSteps, standardTimes, analyzeStepsAgainstAverage } from '../../utils/sample';
import { translate } from '../../utils/translate';
import './css/pagination.css';
import { getAllSamplesRequest } from '../../redux/reducers/sample';

const Trace = ({ loading, error, selectedSample, samples }) => {

  const dispatch = useDispatch();

const delayedSteps = getDelayedSteps(selectedSample, standardTimes);

const [average, setAverage] = useState(undefined);

const formatTime = (milliseconds) => {
  const hours = Math.floor(milliseconds / (1000 * 60 * 60));
  const minutes = Math.floor((milliseconds % (1000 * 60 * 60)) / (1000 * 60));
  return `${hours}h ${minutes}m`;
};


useEffect(() => {
  dispatch(getAllSamplesRequest());
}, []);

useEffect(() => {
  if (samples) {
    setAverage(getDelayedSteps(selectedSample, analyzeStepsAgainstAverage(samples)));
}
}, [samples]);

const StepsList = ({ steps }) => {
  return (


    <div className="sample-list-container">

    <div className="header-row">
    <div className="cell">Paso</div>
      <div  className="cell">Inicio</div>
      <div  className="cell">Fin</div>
      <div className="cell">Responsable</div>
      <div className="cell">Asignado por</div>
      <div className="cell">Hecho por</div>
    </div>


    {steps && steps.map((step, index) => (
      <div key={step.id} className="row">
        <div className="cell">{translate(step.name)}</div>
        <div className="cell">{step.startTimestamp ? new Date(step.startTimestamp).toLocaleString() : 'No disponible'}</div>
        <div className="cell">{step.endTimestamp ? new Date(step.endTimestamp).toLocaleString() : 'No disponible'}</div>
        <div className="cell">{step.assignedTo}</div>
        <div className="cell">{step.assignedBy}</div>
        <div className="cell">{step.doneBy ? step.doneBy : step.sentBy}</div>
      </div>
    ))}
  </div>
  );
};

const DelayedSteps = ({ delayedSteps, type }) => {
  return (
    
      <div>
         
          <h3>Pasos retrasados según tiempo {type}</h3>
          <ul>
              {delayedSteps.map((step, index) => (
                  <li key={index} style={{ marginBottom: '10px', padding: '10px', border: '1px solid #ccc', borderRadius: '5px' }}>
                      <strong>Paso:</strong> {translate(step.step)}<br />
                      <strong>Tiempo Real:</strong> {formatTime(step.actualTime)}<br />
                      <strong>Tiempo {type}:</strong> {formatTime(step.standardTime)}
                  </li>
              ))}
          </ul>
      </div>
  );
};

  return (
    <ContentWrapper title={`Informe de trazabilidad - ${selectedSample.protocolNumber}`}  loading={loading} error={error}>
<div className="sample-details">
            <StepsList steps={selectedSample.steps} />
        </div>

<div>
            <DelayedSteps delayedSteps={delayedSteps} type={"estándar"}/>
        </div>

        {average && (
        <div>
            <DelayedSteps delayedSteps={average} type={"promedio"}/>
        </div>
 )}
  </ContentWrapper>
  );
};

const mapStateToProps = (state) => ({
  loading: sampleSelectors.loading(state),
  modalLoading: sampleSelectors.modalLoading(state),
  error: sampleSelectors.error(state),
  selectedSample: sampleSelectors.selectedSample(state),
  samples: sampleSelectors.samplesAll(state),
});

export default connect(mapStateToProps)(Trace);
