import React from 'react';
import './css/spinner.css';
import Notification from './Notification';
import Loading from './Loading';
import './css/contentwrapper.css';
import { useNavigate } from 'react-router-dom';

const ContentWrapper = ({ title, loading, error, children, onBack }) => {

    const navigate = useNavigate();

    // Solo definimos el back cuando queremos volver al dashbord luego de hacer alguna operacion que involucre alguna pagina mas
    const handleBack = onBack || (() => navigate(-1));

    return (   
        <div className="container">
           <h2> <button className="back-button"  onClick={handleBack}>
                        <span className="arrow-left">{'<'}</span>
                    </button>  {title}</h2>
            <Notification show={error?.show ? error.show : false} message={error ? error.msg : null} type="error" />
            {loading ? (
                <Loading />
            ) : (
                children
            )}
        </div>
    );
};

export default ContentWrapper;