import {call, put, takeLatest } from 'redux-saga/effects';
import {
    getUsersSuccess,
    getUsersFailure,
    types,
    deleteUserSuccess,
    deleteUserFailure,
    addUserFailure,
    addUserSuccess,
    editUserSuccess,
    editUserFailure,
} from '../reducers/user';
import  {mapError} from '../../errorHandler';
import { getRequestWithToken, deleteRequestWithToken, postRequestWithToken, putRequestWithToken } from '../../security/api';

const baseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL;

function* getUsers() {
    try {

        const url = `${baseUrl}/api/users`;

        const response = yield call(getRequestWithToken, url);

        // TODO: el backend debe responder con esta informacion
        response.data.users[0].asignedSamples = 2;
        response.data.users[0].pendingApproval = 3;
        response.data.users[0].readyToDeliver = 4;

        yield put(getUsersSuccess(response.data.users));
    } catch (error) {
        const msg = mapError(error);
        if (error.code == 'ERR_NETWORK') {
            yield put(getUsersFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }
        yield put(getUsersFailure(error.code, msg));
    }
}

function* deleteUser({userId}) {
    try {

        const url = `${baseUrl}/api/users/${userId}`;

        const response = yield call(deleteRequestWithToken, url);

        yield put(deleteUserSuccess());
    } catch (error) {
        const msg = mapError(error);
        if (error.code == 'ERR_NETWORK') {
            yield put(deleteUserFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }
        yield put(deleteUserFailure(error.code, msg));
    }
}

function* addUser({user}) {
    try {

        const url = `${baseUrl}/api/user`;

				const registrationNumber = user.registrationNumber !== '' ? user.registrationNumber : null;
        const response = yield call(postRequestWithToken, url, {
            username: user.username,
            password: user.password,
            email: user.email,
            type: user.type,
					  fullName: user.fullName,
					  registrationNumber,
        });

        yield put(addUserSuccess());

    } catch (error) {
        const msg = mapError(error);
        if (error.code == 'ERR_NETWORK') {
            yield put(addUserFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }
        yield put(addUserFailure(error.code, msg));

    }

}

function* editUser({user}) {
    try {

        const url = `${baseUrl}/api/users/${user.id}`;

				const password = user.password !== '' ? user.password : null;
			  const registrationNumber = user.registrationNumber !== '' ? user.registrationNumber : null;

        const response = yield call(putRequestWithToken, url, {
            email: user.email,
            type: user.type,
						password,
					  fullName: user.fullName,
					  registrationNumber
        });

        yield put(editUserSuccess());
    } catch (error) {
        const msg = mapError(error);
        if (error.code == 'ERR_NETWORK') {
            yield put(editUserFailure(error.code, 'Error al realizar la solicitud.'));
            return;
        }
        yield put(editUserFailure(error.code, msg));
    }
}

export function* watchUser() {
    yield takeLatest(types.LIST_USERS_REQUEST, getUsers);
    yield takeLatest(types.DELETE_USER_REQUEST, deleteUser);
    yield takeLatest(types.ADD_USER_REQUEST, addUser);
    yield takeLatest(types.EDIT_USER_REQUEST, editUser);
}
