import React, {useState} from 'react';
import './css/registrarMuestra.css';

const TipoMuestra = ({formData, handleChange}) => {
	const [error, setError] = useState("");

	const REQUIRED_ERROR_MESSAGE = "Este campo es obligatorio.";


	const handleBlur = (e) => {
		const {name, value} = e.target;
		setError(() => (!value ? REQUIRED_ERROR_MESSAGE : ''));
	};

	const handleInvalid = (e) => {
		e.preventDefault();
		handleBlur(e);
		const firstInvalidElement = document.querySelector('.input-invalid');
		if (firstInvalidElement) {
			firstInvalidElement.scrollIntoView({behavior: 'smooth', block: 'center'});
		}
	};

	return (
		<>
			<div className='input-row'>
				<div className="form-group">
					<label>Subtipo de muestra:</label>
					<select
						name="sampleSubType"
						value={formData.sampleSubType}
						onChange={handleChange}
						onBlur={handleBlur}
						onInvalid={handleInvalid}
						required
						className={error ? 'input-invalid' : ''}
					>
						<option value="">Seleccionar...</option>
						<option value="FREEZING">Congelación</option>
						<option value="REGULAR">Regular</option>
						<option value="PUNCTURE">Punción</option>
						<option value="PAP">Pap</option>
						<option value="LIQUID">Líquido</option>
					</select>
					{error && <div className="input-invalid-disclaimer ">{error}</div>}
				</div>
				<div className="form-group"></div>
			</div>

		</>
	)
};


export default TipoMuestra;
