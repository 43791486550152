import React from 'react';
import './css/ConfirmationModal.css';

const ConfirmationModal = ({ message, onConfirm, onCancel }) => {
      // Transformar el texto con [br] en saltos de línea reales
  const processText = (inputText) => {
    if (!inputText) return null;
    return inputText.split('[br]').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < inputText.split('[br]').length - 1 && <br />}
      </React.Fragment>
    ));
  };
    return (
        <div className="modal-overlay">
            <div className="modal-content">
                    <>
                        <h2>Confirmación</h2>
                        <p>{processText(message)}</p>
                        <div className="button-group">
                            <button className="confirm-button" onClick={onConfirm}>
                                Sí
                            </button>
                            <button className="cancel-button" onClick={onCancel}>
                                No
                            </button>
                        </div>
                    </>
            </div>
        </div>
    );
};

export default ConfirmationModal;
