import React, { useState } from 'react';
import { Bar, Line, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { translate } from '../../utils/translate';
import {formatDateOk} from "../../utils/dates";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);

const StatisticsChart = ({ data, setFilterOption, filterOption, doSearch }) => {
  const colors = [
    'rgba(255, 19, 12, 0.6)', // Color 1
    'rgba(54, 162, 235, 0.6)', // Color 2
    'rgba(255, 206, 86, 0.6)', // Color 3
    'rgba(75, 192, 192, 0.6)', // Color 4
    'rgba(153, 102, 255, 0.6)', // Color 5
    'rgba(255, 159, 64, 0.6)', // Color 6
  ];


  const [filteredDataByDay, setFilteredDataByDay] = useState(data?.totalSamplesPerDay || []);
  const [filteredDataBySubtypeAndDay, setFilteredDataBySubtypeAndDay] = useState(data?.totalSamplesTypePerDay || []);


  // Datos
  const chartDataByType = {
    labels: data?.totalSamplesByType?.map((type) => translate(type.id)) || [],
    datasets: [
      {
        label: 'Muestras por tipo',
        data: data?.totalSamplesByType?.map((type) => type.count) || [],
        backgroundColor: colors.slice(0, data?.totalSamplesByType?.length || 1),
      },
    ],
  };

  const chartDataByAssignee = {
    labels: data?.totalSamplesByAssignee?.map((assignee) => assignee.id) || [],
    datasets: [
      {
        label: 'Muestras asignadas',
        data: data?.totalSamplesByAssignee?.map((assignee) => assignee.count) || [],
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

  const chartDataByDay = {
    labels: data?.totalSamplesPerDay?.map((day) => formatDateOk(day.date)) || [],
    datasets: [
      {
        label: 'Muestras por día',
        data: data?.totalSamplesPerDay?.map((day) => day.count) || [],
        borderColor: 'rgba(153, 102, 255, 1)',
        backgroundColor: 'rgba(153, 102, 255, 0.2)',
      },
    ],
  };

  const subtypes = [
    ...new Set(data?.totalSamplesTypePerDay?.map((sample) => sample.subtype)),
  ];
  const dates = [
    ...new Set(data?.totalSamplesTypePerDay?.map((sample) => sample.date)),
  ];

  const datasetsBySubtype = subtypes.map((subtype, index) => {
    return {
      label: `${translate(subtype)}`,
      data: dates.map((date) => {
        const sampleForDayAndSubtype = data.totalSamplesTypePerDay.find(
          (sample) => sample.date === date && sample.subtype === subtype
        );
        return sampleForDayAndSubtype ? sampleForDayAndSubtype.count : 0;
      }),
      backgroundColor: colors[index % colors.length],
    };
  });

  const chartDataBySubtypeAndDay = {
    labels: dates.map(date => formatDateOk(date)),
    datasets: datasetsBySubtype,
  };

  const chartDataDiagnosisTime = {
    labels: data?.diagnosisTimePerSubtype?.map((item) => translate(item.subtype)) || [],
    datasets: [
      {
        label: 'Tiempo promedio (minutos)',
        data: data?.diagnosisTimePerSubtype?.map((item) => parseFloat(item.averageDifference)) || [],
        backgroundColor: colors.slice(0, data?.diagnosisTimePerSubtype?.length || 1),
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { position: 'top' },
    },
  };

  // KPIs
  const totalSamplesCount = data?.totalSamplesByType?.reduce((acc, type) => acc + type.count, 0) || 0;
  const inProgressSamplesCount = data?.totalInProgressSamplesByType?.reduce((acc, type) => acc + type.count, 0) || 0;

  const applyFilter = () => {
    setFilterOption(filterOption);
    doSearch();
    const today = new Date();
    let startDate;

    if (filterOption === '15') {
      startDate = new Date();
      startDate.setDate(today.getDate() - 15);
    } else if (filterOption === '30') {
      startDate = new Date();
      startDate.setDate(today.getDate() - 30);
    } else if (filterOption === 'month') {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1); // Primer día del mes actual
    }

    const filterDataByDate = (dataset, dateField) => {
      return dataset.filter((item) => {
        const itemDate = new Date(item[dateField]);
        return itemDate >= startDate && itemDate <= today;
      });
    };

    setFilteredDataByDay(filterDataByDate(data?.totalSamplesPerDay || [], 'date'));
    setFilteredDataBySubtypeAndDay(filterDataByDate(data?.totalSamplesTypePerDay || [], 'date'));
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-around', paddingBottom: '20px' }}>
        <div style={{
          textAlign: 'center',
          width: '200px',
          backgroundColor: '#4A90E2',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          padding: '30px',
          margin: '10px',
        }}>
          <h3>Muestras Totales</h3>
          <p style={{ fontSize: '2rem', fontWeight: 'bold', color: '#1A4E91' }}>{totalSamplesCount}</p>
        </div>
        <div style={{
          textAlign: 'center',
          width: '200px',
          backgroundColor: '#A3D9A5',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          padding: '30px',
          margin: '10px',
        }}>
          <h3>Muestras en Proceso</h3>
          <p style={{ fontSize: '2rem', fontWeight: 'bold', color: '#2C6B3D' }}>{inProgressSamplesCount}</p>
        </div>
      </div>

      <div
  style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center', // Asegura alineación vertical
    marginBottom: '20px',
    gap: '10px', // Espaciado uniforme entre elementos
  }}
>
        <span style={{ marginRight: '10px', fontSize: '16px', fontWeight: 'bold' }}>
          Seleccione un período:
        </span>
        <select
          value={filterOption}
          onChange={(e) => setFilterOption(e.target.value)}
          style={{
            padding: '4px 8px',
            fontSize: '14px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            backgroundColor: '#f9f9f9',
            width: '300px',
          }}
        >
          <option value="90d">Últimos 90 días</option>
          <option value="30d">Últimos 30 días</option>
          <option value="14d">Últimos 14 días</option>
          <option value="7d">Últimos 7 días</option>
          <option value="">TODO</option>
        </select>
        <button
          onClick={applyFilter}
          style={{
            padding: '5px 15px',
            cursor: 'pointer',
            fontSize: '14px',
            borderRadius: '4px',
            border: '1px solid #ccc',
            backgroundColor: '#007bff',
            color: '#fff',
          }}
        >
          Filtrar 
        </button>
      </div>


      {/* Charts */}
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
        <div style={{ flex: '1 1 12%' }}>
          <h3>Muestras por subtipo</h3>
          <Doughnut data={chartDataByType} options={options} />
        </div>
        <div style={{ flex: '1 1 45%', paddingLeft: '10px' }}>
          <h3>Muestras asignadas por persona</h3>
          <Bar data={chartDataByAssignee} options={options} />
        </div>
        <div style={{ flex: '1 1 45%', paddingTop: '50px' }}>
          <h3>Muestras por día</h3>
          <Line data={chartDataByDay} options={options} />
        </div>
        <div style={{ flex: '1 1 45%', paddingTop: '50px' }}>
          <h3>Muestras por subtipo y día</h3>
          <Bar data={chartDataBySubtypeAndDay} options={{ ...options, scales: { x: { stacked: true }, y: { stacked: true } } }} />
        </div>
        <div style={{ flex: '1 1 45%', paddingTop: '50px' }}>
          <h3>Tiempo promedio de diagnóstico</h3>
          <Bar data={chartDataDiagnosisTime} options={options} />
        </div>
        <div style={{ flex: '1 1 45%' }}>
        </div>
      </div>
    </div>
  );
};

export default StatisticsChart;
