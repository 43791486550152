import React, {useState} from 'react';
import './css/registrarMuestra.css';

const DatosPersonales = ({formData, handleChange}) => {
	const [errors, setErrors] = useState({});

	const REQUIRED_ERROR_MESSAGE = "Este campo es obligatorio.";

	// Función para validar el número de DNI
	const validateDni = (dni) => {
		const dniPattern = /^[0-9]{7,8}$/; // Validar que el DNI tenga 7 u 8 dígitos numéricos
		return dniPattern.test(dni);
	};

	const validateEmail = (email) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	const handleDniChange = (e) => {
		const {value} = e.target;
		handleChange(e); // Llama a la función handleChange para actualizar el estado

		// Validar el DNI y establecer el mensaje de error
		if (value && !validateDni(value)) {
			setErrors((prevErrors) => ({
				...prevErrors,
				dni: 'El DNI debe ser numérico y tener entre 7 y 8 dígitos.',
			}));
		} else if (!value) {
			setErrors((prevErrors) => ({
				...prevErrors,
				dni: REQUIRED_ERROR_MESSAGE,
			}));
		} else {
			setErrors((prevErrors) => ({...prevErrors, dni: ''}));
		}
	};

	const handleBirthdateChange = (e) => {
		const {value} = e.target;
		const today = new Date().toISOString().split("T")[0];
		handleChange(e); // Actualiza el estado en el componente principal

		// Validar si la fecha de nacimiento es menor o igual a hoy
		if (value > today) {
			setErrors((prevErrors) => ({
				...prevErrors,
				patientBirthdate: 'La fecha de nacimiento no puede ser en el futuro.',
			}));
		} else if (!value) {
			setErrors((prevErrors) => ({
				...prevErrors,
				patientBirthdate: REQUIRED_ERROR_MESSAGE,
			}));
		} else {
			setErrors((prevErrors) => ({...prevErrors, patientBirthdate: ''}));
		}
	}

	const handleEmailChange = (e) => {
		const {value} = e.target;
		handleChange(e); // Llama a la función handleChange para actualizar el estado

		// Validar el DNI y establecer el mensaje de error
		if (value && !validateEmail(value)) {
			setErrors((prevErrors) => ({
				...prevErrors,
				email: 'El formato ingresado no es válido.',
			}));
		} else if (!value) {
			setErrors((prevErrors) => ({
				...prevErrors,
				email: REQUIRED_ERROR_MESSAGE,
			}));
		} else {
			setErrors((prevErrors) => ({...prevErrors, email: ''}));
		}
	};

	const handleBlur = (e) => {
		const {name, value} = e.target;
		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: !value ? REQUIRED_ERROR_MESSAGE : '',
		}));
	};

	const handleInvalid = (e) => {
		const {name} = e.target;
		e.preventDefault();
		if(name === 'dni'){
			handleDniChange(e)
		}
		else if (name === 'patientBirthdate'){
			handleBirthdateChange(e)
		}
		else if (name === 'email'){
			handleEmailChange(e)
		}
		else {
			handleBlur(e);
		}
		const firstInvalidElement = document.querySelector('.input-invalid');
		if (firstInvalidElement) {
			firstInvalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	};

	return (
		<>
			<h2>Datos personales del paciente</h2>
			<div className="input-row">
				<div className="form-group">
					<label>Nombre y apellido:</label>
					<input
						type="text"
						name="nombreApellido"
						value={formData.nombreApellido}
						onChange={handleChange}
						onInvalid={handleInvalid}
						onBlur={handleBlur}
						required
						className={errors.nombreApellido ? 'input-invalid' : ''}
					/>
					{errors.nombreApellido && <div className="input-invalid-disclaimer ">{errors.nombreApellido}</div>}
				</div>

				<div className="form-group">
					<label>Fecha de nacimiento:</label>
					<input
						type="date"
						name="patientBirthdate"
						value={formData.patientBirthdate}
						onChange={handleBirthdateChange}
						onBlur={handleBirthdateChange}
						onInvalid={handleInvalid}
						required
						max={new Date().toISOString().split("T")[0]}
						className={errors.patientBirthdate ? 'input-invalid' : ''}
					/>
					{errors.patientBirthdate && <div className="input-invalid-disclaimer ">{errors.patientBirthdate}</div>}
				</div>
			</div>

			<div className="input-row">

				<div className="form-group">
					<label>DNI:</label>
					<input
						type="text"
						name="dni"
						value={formData.dni}
						onChange={handleDniChange} // Usar la función de manejo de cambio personalizada
						onBlur={handleDniChange}
						onInvalid={handleInvalid}
						required
						pattern="^[0-9]{7,8}$"
						className={errors.dni ? 'input-invalid' : ''}
					/>
					{errors.dni && <div className="input-invalid-disclaimer ">{errors.dni}</div>}
				</div>

				<div className="form-group">
					<label>Domicilio:</label>
					<input
						type="text"
						name="domicilio"
						value={formData.domicilio}
						onChange={handleChange}
						onBlur={handleBlur}
						onInvalid={handleInvalid}
						required
						className={errors.domicilio ? 'input-invalid' : ''}
					/>
					{errors.domicilio && <div className="input-invalid-disclaimer ">{errors.domicilio}</div>}
				</div>
			</div>
			<div className="input-row">
				<div className="form-group">
					<label>Número de historia clínica:</label>
					<input
						type="text"
						name="clinicHistoryNumber"
						value={formData.clinicHistoryNumber}
						onChange={handleChange}
						onInvalid={handleInvalid}
						required
						onBlur={handleBlur}
						className={errors.clinicHistoryNumber ? 'input-invalid' : ''}
					/>
					{errors.clinicHistoryNumber && <div className="input-invalid-disclaimer ">{errors.clinicHistoryNumber}</div>}
				</div>
				<div className="form-group"></div>
			</div>

			<div className="input-row">
				<div className="form-group">
					<label>Nacionalidad:</label>
					<select
						name="nacionalidad"
						value={formData.nacionalidad}
						onChange={handleChange}
						required
						onBlur={handleBlur}
						onInvalid={handleInvalid}
						className={errors.nacionalidad ? 'input-invalid' : ''}
					>
						<option value="">Seleccionar...</option>
						<option value="Argentina">Argentina</option>
						<option value="Antigua y Barbuda">Antigua y Barbuda</option>
						<option value="Bahamas">Bahamas</option>
						<option value="Barbados">Barbados</option>
						<option value="Belice">Belice</option>
						<option value="Bolivia">Bolivia</option>
						<option value="Brasil">Brasil</option>
						<option value="Canadá">Canadá</option>
						<option value="Chile">Chile</option>
						<option value="Colombia">Colombia</option>
						<option value="Costa Rica">Costa Rica</option>
						<option value="Cuba">Cuba</option>
						<option value="Dominica">Dominica</option>
						<option value="Ecuador">Ecuador</option>
						<option value="El Salvador">El Salvador</option>
						<option value="Estados Unidos">Estados Unidos</option>
						<option value="Granada">Granada</option>
						<option value="Guyana">Guyana</option>
						<option value="Guatemala">Guatemala</option>
						<option value="Haití">Haití</option>
						<option value="Jamaica">Jamaica</option>
						<option value="México">México</option>
						<option value="Nicaragua">Nicaragua</option>
						<option value="Panamá">Panamá</option>
						<option value="Paraguay">Paraguay</option>
						<option value="Perú">Perú</option>
						<option value="Puerto Rico">Puerto Rico</option>
						<option value="República Dominicana">República Dominicana</option>
						<option value="San Cristóbal y Nieves">San Cristóbal y Nieves</option>
						<option value="San Vicente y las Granadinas">San Vicente y las Granadinas</option>
						<option value="Santa Lucía">Santa Lucía</option>
						<option value="Trinidad y Tobago">Trinidad y Tobago</option>
						<option value="Uruguay">Uruguay</option>
						<option value="Venezuela">Venezuela</option>
					</select>
					{errors.nacionalidad && <div className="input-invalid-disclaimer ">{errors.nacionalidad}</div>}
				</div>

				<div className="form-group">
					<label>Profesión:</label>
					<input
						type="text"
						name="profesion"
						value={formData.profesion}
						onChange={handleChange}
						onBlur={handleBlur}
						onInvalid={handleInvalid}
						required
						className={errors.profesion ? 'input-invalid' : ''}
					/>
					{errors.profesion && <div className="input-invalid-disclaimer ">{errors.profesion}</div>}
				</div>
			</div>
			<div className="input-row">

				<div className="form-group">
					<label>Estado civil:</label>
					<select
						name="estadoCivil"
						value={formData.estadoCivil}
						onChange={handleChange}
						required
						onBlur={handleBlur}
						onInvalid={handleInvalid}
						className={errors.estadoCivil ? 'input-invalid' : ''}
					>
						<option value="">Seleccionar...</option>
						<option value="Soltero">Soltero</option>
						<option value="Casado">Casado</option>
						<option value="Divorciado">Divorciado</option>
						<option value="Viudo">Viudo</option>
					</select>
					{errors.estadoCivil && <div className="input-invalid-disclaimer ">{errors.estadoCivil}</div>}
				</div>

				<div className="form-group">
					<label>Email:</label>
					<input
						type="email"
						name="email"
						value={formData.email}
						onChange={handleEmailChange}
						onInvalid={handleInvalid}
						required
						pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
						onBlur={handleEmailChange}
						className={errors.email ? 'input-invalid' : ''}
					/>
					{errors.email && <div className="input-invalid-disclaimer ">{errors.email}</div>}
				</div>
			</div>
		</>
	);
};

export default DatosPersonales;
