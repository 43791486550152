import React from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import {ErrorBoundary} from 'react-error-boundary';
import Error from './components/Error';
import PrivateRoutes from './PrivateRoutes';
import RegistrarMuestra from './components/muestras/RegistrarMuestra';
import SesionController from './components/SessionController';
import Security from './components/Security';
import {connect} from "react-redux";
import {selectors as loginSelectors} from "./redux/reducers/auth"
import UserManagement from './components/users/UserManagement';
import CreateUser from './components/users/CreateUser';
import ModifyUser from './components/users/ModifyUser';
import SampleManagement from './components/muestras/SampleManagement';
import history from './history';
import DoctorSelector from './components/muestras/DoctorSelector';
import SampleHistology from './components/muestras/SampleHistology';
import Macroscopy from './components/muestras/Macroscopy';
import Metrics from './components/Metrics';
import Diagnostico from './components/Diagnostico';
import SampleDiagnosis from './components/muestras/SampleDiagnosis';
import SampleInforme from './components/muestras/SampleInforme'; 
import Informe from './components/Informe';
import SampleRegistrarUbicacion from './components/muestras/SampleRegistrarUbicacion';
import GuardarMuestra from './components/GuardarMuestra';
import ConsultarUbicacionMuestra from './components/ConsultarUbicacionMuestra';
import { WebSocketProvider } from './components/WebSocketProvider';
import SampleEnviarInforme from './components/muestras/SampleEnviarInforme';
import EnviarInforme from './components/EnviarInforme';
import Trace from './components/muestras/Trace';
import SampleImprimirInforme from './components/muestras/SampleImprimirInforme';
import ImprimirInforme from './components/ImprimirInforme';

function App({auth}) {

    return (
        <ErrorBoundary fallback={<Error/>}>
            <WebSocketProvider>
            <Router history={history}>

                <SesionController/>
                <Routes>

                <Route exact path="/"  element={<Home/>} />

                   <Route element={<PrivateRoutes />}>
                        <Route path="/dashboard"  element={<Dashboard />}  />
                        <Route 
                            exact path="/registrarMuestra" 
                            element={<Security 
                                element={RegistrarMuestra} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['REGISTRAR_MUESTRA']}
                            />} 
                        />
                          <Route 
                            exact path="/gestionUsuarios" 
                            element={<Security 
                                element={UserManagement} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['GESTION_USUARIOS']}
                            />} 
                        />
                         <Route 
                            exact path="/crearUsuario" 
                            element={<Security 
                                element={CreateUser} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['GESTION_USUARIOS']}
                            />} 
                        />
                         <Route 
                            exact path="/modificarUsuario" 
                            element={<Security 
                                element={ModifyUser} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['GESTION_USUARIOS']}
                            />} 
                        />
                        <Route 
                            exact path="/listarHistologia" 
                            element={<Security 
                                element={SampleManagement} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['HISTO_LAB_SHOW']}
                                sampleType="HISTOLOGIC"
                                readOnly
                            />}
                        />
                         <Route 
                            exact path="/listarCitologia" 
                            element={<Security 
                                element={SampleManagement} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['CITO_LAB_SHOW']}
                                sampleType="CITOLOGIC"
                                readOnly
                            />}
                        />
                         <Route 
                            exact path="/asignarMedico" 
                            element={<Security 
                                element={SampleManagement} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['HISTO_LAB_SHOW','DOCTOR_ASSIGNATION','TECHNIC_ASSIGNATION']}
                                sampleType="HISTOLOGIC"
                                step="assignDoctor"
                            />}
                        />
                         <Route 
                            exact path="/asignarTecnico" 
                            element={<Security 
                                element={SampleManagement} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['CITO_LAB_SHOW','TECHNIC_ASSIGNATION']}
                                sampleType="CITOLOGIC"
                                step="assignTechnician"
                            />}
                        />
                         <Route
                            exact path="/seleccionProfesional"
                            element={<Security
                                element={DoctorSelector}
                                permissions={auth.data ? auth.data.functions: []}
                                requiredPermissions={['TECHNIC_ASSIGNATION', 'DOCTOR_ASSIGNATION']} // puede ser tambien DOCTOR_ASSIGNATION
                            />} 
                        />
                         <Route
                            exact path="/macroscopia"
                            element={<Security
                                element={SampleHistology}
                                permissions={auth.data ? auth.data.functions: []}
                                requiredPermissions={['MACROSCOPY']}
                                step="macroscopy"
                            />} 
                        />
                        <Route
                            exact path="/macroscopiaRegistro"
                            element={<Security
                                element={Macroscopy}
                                permissions={auth.data ? auth.data.functions: []}
                                requiredPermissions={['MACROSCOPY']}
                            />} 
                        />
                         <Route 
                            exact path="/diagnostico" 
                            element={<Security 
                                element={SampleDiagnosis} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['DIAGNOSIS']}
                                sampleType="HISTOLOGIC"
                                step="diagnosis"
                            />}
                        />
                          <Route 
                            exact path="/crearDiagnostico" 
                            element={<Security 
                                element={Diagnostico} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['DIAGNOSIS']}
                                sampleType="HISTOLOGIC"
                                step="diagnosis"
                            />}
                        />
                          <Route
                            exact path="/informe"
                            element={<Security
                                element={SampleInforme}
                                permissions={auth.data ? auth.data.functions: []}
                                requiredPermissions={['FINAL_REPORT']}
                                sampleType="HISTOLOGIC"
                                step="generateReport"
                            />} 
                        />
                          <Route 
                            exact path="/crearInforme" 
                            element={<Security 
                                element={Informe} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['FINAL_REPORT']}
                                sampleType="HISTOLOGIC"
                                step="generateReport"
                            />}
                        />

                        <Route 
                            exact path="/listaInformesListos" 
                            element={<Security 
                                element={SampleEnviarInforme} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['SEND_FINAL_REPORT_EMAIL']}
                                sampleType="HISTOLOGIC"
                                step="sendReport"
                            />}
                        />

                        <Route 
                            exact path="/enviarInforme" 
                            element={<Security 
                                element={EnviarInforme} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['SEND_FINAL_REPORT_EMAIL']}
                                sampleType="HISTOLOGIC"
                                
                            />}
                        />


                        <Route 
                            exact path="/estadisticas" 
                            element={<Security 
                                element={Metrics} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['STATISTICS']}
                            />}
                        />

                        <Route 
                            exact path="/ubicacionMuestra" 
                            element={<Security 
                                element={SampleRegistrarUbicacion} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['ARCHIVE_SAMPLES']}
                                step="archiveSample"
                            />}
                        />

                        <Route 
                            exact path="/registrarUbicacionMuestra" 
                            element={<Security 
                                element={GuardarMuestra} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['ARCHIVE_SAMPLES']}
                            />}
                        />

                        <Route 
                            exact path="/consultarUbicacion" 
                            element={<Security 
                                element={ConsultarUbicacionMuestra} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['SEARCH_SAMPLES_IN_ARCHIVE']}
                            />}
                        />

                        <Route 
                            exact path="/trazabilidad" 
                            element={<Security 
                                element={Trace} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['STATISTICS']}
                            />}
                        />

                        <Route 
                            exact path="/imprimirInforme" 
                            element={<Security 
                                element={SampleImprimirInforme} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['PRINT_FINAL_REPORT']}
                                readOnly
                            />}
                        />

                        <Route 
                            exact path="/imprInforme" 
                            element={<Security 
                                element={ImprimirInforme} 
                                permissions={auth.data ? auth.data.functions: []} 
                                requiredPermissions={['PRINT_FINAL_REPORT']}
                            />}
                        />


                    </Route>

                    <Route exact path="*" element={<Error message="Funcionalidad no disponible"/>}/>

                    <Route exact path="/error" element={<Error message="Sitio no encontrado"/>}/>

                </Routes>
            </Router>
            </WebSocketProvider>
        </ErrorBoundary>
    );
}

const mapStateToProps = (state) => ({
    auth: loginSelectors.login(state),
});

export default connect(mapStateToProps)(App);
