import {all, call, put, takeLatest} from 'redux-saga/effects';
import axios from 'axios';
import {watchLoginSaga} from "./auth";
import { watchSessionSaga } from './session';
import { watchEntitySaga } from './entity';
import { watchSampleSaga } from './sample';
import { watchUser } from './user';
import { watchProfessionalSaga } from './professional';
import { watchMetricsSaga } from './metrics';

const baseUrl = process.env.REACT_APP_BACKEND_API_BASE_URL;

function* getAppDescription() {
    try {
       const config = {
            headers: {
                'Access-Control-Allow-Origin': '*', // Or specific origins such as ''
            },
        };
        const response = yield call(axios.get, `${baseUrl}/getSystemData`, config);
        yield put({type: 'GET_SYSTEM_DATA_SUCCESS', payload: response.data});
    } catch (error) {
        yield put({type: 'GET_SYSTEM_DATA_FAILURE', payload: error.message});
    }
}

function* watchFetchData() {
    yield takeLatest('GET_SYSTEM_DATA_REQUEST', getAppDescription);
}

export default function* rootSaga() {
  yield all([watchFetchData(),watchLoginSaga(),watchSessionSaga(),watchEntitySaga(),watchSampleSaga(),watchUser(), watchProfessionalSaga(), watchMetricsSaga()]);
}
