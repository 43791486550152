import React, {useState} from 'react';
import './css/registrarMuestra.css';

const MaterialGinecologico = ({formData, handleChange}) => {
	const [errors, setErrors] = useState({});


	const handleDateChange = (e) => {
		const {value, name} = e.target;
		const today = new Date().toISOString().split("T")[0];
		handleChange(e); // Actualiza el estado en el componente principal

		// Validar si la fecha de nacimiento es menor o igual a hoy
		if (value > today) {
			setErrors((prevErrors) => ({
				...prevErrors,
				[name]: 'La fecha ingresada no es válida.',
			}));
		} else {
			setErrors((prevErrors) => ({...prevErrors, [name]: ''}));
		}
	}

	const handleInvalid = (e) => {
		e.preventDefault();
		handleDateChange(e);
		const firstInvalidElement = document.querySelector('.input-invalid');
		if (firstInvalidElement) {
			firstInvalidElement.scrollIntoView({behavior: 'smooth', block: 'center'});
		}
	};

	return (
		<>

			<h2>Material ginecológico</h2>

			<div className="input-row">
				<div className="form-group">
					<label>Ciclo menstrual (opcional):</label>
					<input
						type="text"
						name="cicloMenstrual"
						value={formData.cicloMenstrual}
						onChange={handleChange}
					/>
				</div>
			</div>

			<div className="input-row">

				<div className="form-group">
					<label>Última menstruación (opcional):</label>
					<input
						type="date"
						name="ultimaMenstruacion"
						value={formData.ultimaMenstruacion}
						onChange={handleDateChange}
						onBlur={handleDateChange}
						max={new Date().toISOString().split("T")[0]}
						onInvalid={handleInvalid}
						className={errors.ultimaMenstruacion ? 'input-invalid' : ''}
					/>
					{errors.ultimaMenstruacion && <div className="input-invalid-disclaimer ">{errors.ultimaMenstruacion}</div>}
				</div>

				<div className="form-group">
					<label>Fecha del último parto (opcional):</label>
					<input
						type="date"
						name="fechaUltimoParto"
						value={formData.fechaUltimoParto}
						onChange={handleChange}
						onBlur={handleDateChange}
						max={new Date().toISOString().split("T")[0]}
						onInvalid={handleInvalid}
						className={errors.fechaUltimoParto ? 'input-invalid' : ''}
					/>
					{errors.fechaUltimoParto && <div className="input-invalid-disclaimer ">{errors.fechaUltimoParto}</div>}
				</div>
			</div>


		</>
	)
};


export default MaterialGinecologico;
