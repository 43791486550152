// Mapeo de ids de permisos con sus urls
export const permission =  [
{
  REGISTRAR_MUESTRA: 'RECEIVE_SAMPLE',
  name: 'Registrar muestra',
  link: '/registrarMuestra',
  icon: undefined
},
{
  HISTO_LAB_SHOW: 'HISTO_LAB_SHOW',
  name: 'Ver muestras histológicas',
  link: '/listarHistologia',
  icon: undefined
},
{
  CITO_LAB_SHOW: 'CITO_LAB_SHOW',
  name: 'Ver muestras citológicas',
  link: '/listarCitologia',
  icon: undefined
},
{
  DOCTOR_ASSIGNATION: 'DOCTOR_ASSIGNATION',
  name: 'Asignar médico',
  link: '/asignarMedico',
  icon: undefined
},
{
  TECHNIC_ASSIGNATION: 'TECHNIC_ASSIGNATION',
  name: 'Asignar técnico',
  link: '/asignarTecnico',
  icon: undefined
},
{
  TECHNIC_ASSIGNATION: 'TECHNIC_ASSIGNATION',
  name: 'seleccion profesional',
  link: '/seleccionProfesional',
  icon: undefined,
  nomenu: true, // Es un permiso que indica que no debe renderizarse como opcion de menu
},
{
  DOCTOR_ASSIGNATION: 'DOCTOR_ASSIGNATION',
  name: 'seleccion profesional',
  link: '/seleccionProfesional',
  icon: undefined,
  nomenu: true, // Es un permiso que indica que no debe renderizarse como opcion de menu
},
/*{
  REQUEST_ADDITIONAL_SUBSAMPLE: 'REQUEST_ADDITIONAL_SUBSAMPLE',
  name: 'Agregar submuestra histologica',
  link: '/agregarSubMuestraHist',
  icon: undefined
},*/
{
  MACROSCOPY: 'MACROSCOPY',
  name: 'Macroscopía',
  link: '/macroscopia',
  icon: undefined
},
{
  DIAGNOSIS: 'DIAGNOSIS',
  name: 'Crear diagnóstico',
  link: '/crearDiagnostico',
  icon: undefined,
  nomenu: true,
},
{
  DIAGNOSIS: 'DIAGNOSIS',
  name: 'Crear diagnóstico',
  link: '/diagnostico',
  icon: undefined
},
/*{
  REQUEST_ADDITIONAL_PROCESS: 'REQUEST_ADDITIONAL_PROCESS',
  name: 'Agregar un caset histologia',
  link: '/agregarCaset',
  icon: undefined
},*/
{
  HISTO_LAB_PROCESS: 'HISTO_LAB_PROCESS',
  name: 'lab',
  link: '',
  icon: undefined,
  nomenu: true, // Es un permiso que indica que no debe renderizarse como opcion de menu
},
{
  CITO_LAB_PROCESS: 'CITO_LAB_PROCESS',
  name: 'lab',
  link: '',
  icon: undefined,
  nomenu: true, // Es un permiso que indica que no debe renderizarse como opcion de menu
},
{
  ARCHIVE_SAMPLES: 'ARCHIVE_SAMPLES',
  name: 'Registrar ubicación de muestra',
  link: '/ubicacionMuestra',
  icon: undefined,
},
{
  SEARCH_SAMPLES_IN_ARCHIVE: 'SEARCH_SAMPLES_IN_ARCHIVE',
  name: 'Consultar ubicación de muestra',
  link: '/consultarUbicacion',
  icon: undefined,
},
{
  FINAL_REPORT: 'FINAL_REPORT',
  name: 'Crear informe final',
  link: '/informe',
  icon: undefined,
},
{
  FINAL_REPORT: 'FINAL_REPORT',
  name: 'Informes',
  link: '/crearInforme',
  icon: undefined,
  nomenu: true,
},
{
  SEND_FINAL_REPORT_EMAIL: 'SEND_FINAL_REPORT_EMAIL',
  name: 'Enviar informe final',
  link: '/listaInformesListos',
  icon: undefined,
},
{
  PRINT_FINAL_REPORT: 'PRINT_FINAL_REPORT',
  name: 'Imprimir informe final',
  link: '/imprimirInforme',
  icon: undefined,
},
{
  SEND_FINAL_REPORT_EMAIL: 'SEND_FINAL_REPORT_EMAIL',
  name: 'Enviar informe',
  link: '/vistaInforme',
  icon: undefined,
  nomenu: true,
},
{
  GESTION_USUARIOS: 'USER_MANAGEMENT',
  name: 'Gestión de usuarios',
  link: '/gestionUsuarios',
  icon: undefined
},
{
  STATISTICS: 'STATISTICS',
  name: 'Estadísticas',
  link: '/estadisticas',
  icon: undefined,
},
];

// Mapea los IDs de permisos de la api con los datos requieridos para el menu

export const getPermissionsByValues = (values) => {
  return permission.filter(perm =>
    values.includes(Object.values(perm)[0])
  );
};

// Verifica que el permiso de la opcion seleccionada exista en el usuario

export const permissionExists = (id, permissions) => {
  return permissions.some(perm =>
    Object.keys(perm)[0] === id
  );
};
