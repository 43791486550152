import React from 'react';

const Footer = ({version, description}) => {
  return (
    <footer style={styles.footer}>
      <p style={styles.text}>{description} - Versión: {version}</p>
    </footer>
  );
};

const styles = {
    footer: {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: '#282c34',
      color: 'white',
      textAlign: 'center',
      padding: '10px 0',
    },
    text: {
      margin: 0,
    },
  };
  
export default Footer;