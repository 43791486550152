import React, { useState, useEffect } from 'react';
import { useDispatch, connect } from 'react-redux';
import { selectors } from '../../redux/reducers/user';
import ContentWrapper from '../commons/ContentWrapper';
import { editUserRequest } from '../../redux/reducers/user';
import Modal from '../commons/Modal';
import { UserType } from '../../constants';
import { useNavigate } from 'react-router-dom';
import ConfirmationModal from '../commons/ConfirmationModal';

const ModifyUser = ({ modalLoading, error, selectedUser }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
	const [errors, setErrors] = useState({});

  const [showModalConfirmation, setShowModalConfirmation] = useState(false);

  const [user, setUser] = useState({
    username: '',
    password: '',
    email: '',
    type: '',
    id: '',
		fullName: '',
		registrationNumber: ''
  });

	const REQUIRED_ERROR_MESSAGE = "Este campo es obligatorio.";


  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value
    });
  };

	const validateEmail = (email) => {
		const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailPattern.test(email);
	};

	const handleEmailChange = (e) => {
		const {value} = e.target;
		handleChange(e); // Llama a la función handleChange para actualizar el estado

		// Validar el DNI y establecer el mensaje de error
		if (value && !validateEmail(value)) {
			setErrors((prevErrors) => ({
				...prevErrors,
				email: 'El formato ingresado no es válido.',
			}));
		} else if (!value) {
			setErrors((prevErrors) => ({
				...prevErrors,
				email: REQUIRED_ERROR_MESSAGE,
			}));
		} else {
			setErrors((prevErrors) => ({...prevErrors, email: ''}));
		}
	};

	const handleBlur = (e) => {
		const {name, value} = e.target;
		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: !value ? REQUIRED_ERROR_MESSAGE : '',
		}));
	};

	const handleInvalid = (e) => {
		const {name} = e.target;
		e.preventDefault();
		if (name === 'email'){
			handleEmailChange(e)}
		else{
			handleBlur(e);}
		const firstInvalidElement = document.querySelector('.input-invalid');
		if (firstInvalidElement) {
			firstInvalidElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	};

  const handleSubmit = (e) => {
    e.preventDefault();
		setShowModalConfirmation(true);
  };

  useEffect(() => {
    if (selectedUser) {
      setUser({
        username: selectedUser.username || '',
				fullName: selectedUser.fullName || '',
        email: selectedUser.email || '',
				password: '',
        type: selectedUser.type || '',
        id: selectedUser.id || '',
				registrationNumber: selectedUser.registrationNumber || ''
      });
    }
  }, [selectedUser]);

  const confirmOperation = () => {
    setShowModal(false);
    navigate('/gestionUsuarios');
  };

  const confirmEdit = () => {
    setShowModalConfirmation(false);
    setShowModal(true);
    dispatch(editUserRequest(user));
  };


  return (
    <ContentWrapper title="Modificar usuario" error={error}>
			<form onSubmit={handleSubmit} className="user-form">
				<div className="input-row">
					<div className="form-group">
						<label htmlFor="username">Nombre de usuario:</label>
						<input
							type="text"
							id="username"
							name="username"
							value={user.username}
							readOnly
							disabled
						/>
					</div>
					<div className="form-group"></div>
				</div>

				<div className="input-row">
					<div className="form-group">
						<label htmlFor="fullName">Nombre y apellido:</label>
						<input
							type="text"
							id="fullName"
							name="fullName"
							value={user.fullName}
							onChange={handleChange}
							onInvalid={handleInvalid}
							onBlur={handleBlur}
							required
							className={errors.fullName ? 'input-invalid' : ''}
						/>
						{errors.fullName && <div className="input-invalid-disclaimer ">{errors.fullName}</div>}
					</div>
					<div className="form-group"></div>
				</div>

				<div className="input-row">
					<div className="form-group">
						<label htmlFor="registrationNumber">Número de matrícula (opcional):</label>
						<input
							type="text"
							id="registrationNumber"
							name="registrationNumber"
							value={user.registrationNumber}
							onChange={handleChange}
							onInvalid={handleInvalid}
							onBlur={handleBlur}
							className={errors.registrationNumber ? 'input-invalid' : ''}
						/>
						{errors.registrationNumber && <div className="input-invalid-disclaimer ">{errors.registrationNumber}</div>}
					</div>
					<div className="form-group"></div>
				</div>

				<div className="input-row">
					<div className="form-group">
						<label htmlFor="password">Nueva Contraseña:</label>
						<input
							type="password"
							id="password"
							name="password"
							value={user.password}
							onChange={handleChange}
							className={errors.password ? 'input-invalid' : ''}
						/>
						{errors.password && <div className="input-invalid-disclaimer ">{errors.password}</div>}

					</div>
					<div className="form-group"></div>
				</div>

				<div className="input-row">
					<div className="form-group">
						<label htmlFor="email">Correo electrónico:</label>
						<input
							type="email"
							id="email"
							name="email"
							value={user.email}
							onChange={handleEmailChange}
							onInvalid={handleInvalid}
							onBlur={handleEmailChange}
							required
							pattern="^[^\s@]+@[^\s@]+\.[^\s@]+$"
							className={errors.email ? 'input-invalid' : ''}
						/>
						{errors.email && <div className="input-invalid-disclaimer ">{errors.email}</div>}

					</div>
					<div className="form-group"></div>
				</div>
				<div className="input-row">
					<div className="form-group">
						<label htmlFor="type">Tipo de usuario:</label>
						<select
							id="type"
							name="type"
							value={user.type}
							onChange={handleChange}
						>
							<option value="" disabled>Seleccione un tipo</option>
							{Object.values(UserType).map(({id, description}) => (
								<option key={id} value={id}>{description}</option>
							))}
						</select>
					</div>
					<div className="form-group"></div>
				</div>
				<button type="submit" className="submit-button">Modificar</button>
			</form>
			{showModal && (
				<Modal
					modalLoading={modalLoading}
					error={error}
					onClose={() => confirmOperation()}
				/>
			)}
			{showModalConfirmation && (
				<ConfirmationModal
					message={`Confirma modificación del usuario ${user.username}?`}
					onConfirm={() => confirmEdit()}
					onCancel={() => setShowModalConfirmation(false)}
				/>
			)}
		</ContentWrapper>
	);
};

const mapStateToProps = (state) => ({
	modalLoading: selectors.modalLoading(state),
	error:
		selectors.error(state),
	selectedUser:
		selectors.getSelectedUser(state),
});

export default connect(mapStateToProps)(ModifyUser);
